import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link, NavLink } from "react-router-dom";
import LanguageMenu from '../../helpers/languageSwitcher';
import logoWhite from 'assets/images/logo/shovv-logo-white.svg'
import logoBlack from 'assets/images/logo/shovv-logo-black.svg'

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 71) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* Header */}
      <header className={navbar ? "main-header fixed-header" : "main-header"}>
        <nav className="container">
          <div className=" header-transparent">
            {/* Brand */}
      
            <NavLink to="/en/vue">
              <img
                className="navbar-brand white-logo"
                src={logoWhite}
                alt="SHOVV - software development services"
              />
              <img
                id="logo"
                className="navbar-brand black-logo"
                src={logoBlack}
                alt="SHOVV - software development services"
              />
            </NavLink>
            {/* / */}
            {/* Mobile Toggle */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleClick}
            >
              <div className={click ? "hamburger active" : "hamburger"}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            {/* / */}
            {/* Top Menu */}
            <div
              className={
                click
                  ? "mobile-menu navbar-collapse justify-content-end active"
                  : "mobile-menu navbar-collapse justify-content-end"
              }
            >
              <Scrollspy
                className="anchor_nav navbar-nav ml-auto"
                items={["logo","home", "onas", "uslugi", "work", "blog", "contact"]}
                currentClassName="current"
                offset={-71}
              >
                <li>
                  <Link className="nav-link" to="/en/vue" onClick={handleClick}>
                    <span>Vue</span>
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/en/react" onClick={handleClick}>
                    <span>React</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    to="/en/phalcon"
                    onClick={handleClick}
                  >
                    <span>Phalcon</span>
                  </Link>
                </li>
               
                <li>
                  <a className="nav-link" href="#contact" onClick={handleClick}>
                    <span>Contact</span>
                  </a>
                </li>
                <LanguageMenu />
              </Scrollspy>
            </div>
            {/* / */}
          </div>
          {/* Container */}
        </nav>
        {/* Navbar */}
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;

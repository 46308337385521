import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      firstLetter: 'M',
      opinions: '3 opinie',
      date: '8 lipca',
      imageName: 'team-1',
      desc: `Kompleksowa obsługa, profesjonalnie, terminowo, z pomysłem, polecam!`,
      reviewerName: 'Michał Wasiluk',
      rating: '5.0',
    },
    {
      firstLetter: 'K',
      opinions: '1 opinia',
      date: '17 grudnia',
      imageName: 'team-2',
      desc: `Szybko, tanio i solidnie - ciężko w dzisiejszych czasach znaleźć firmy w branży łączące te 3 cechy. Showoff ogarnia na szczęście tą ciężką kombinację, dlatego wybieram właśnie ich!`,
      reviewerName: 'Karol Kiryk',
      rating: '5',
    },
    {
      firstLetter: 'J',
      opinions: '2 opinie',
      date: '22 grudnia',
      imageName: 'team-4',
      desc: `Agencja SHOVV charakteryzuje się kreatywnym podejściem do zlecenia, dużą wiedzą z zakresu nowoczesnego marketingu i reklamy. Bardzo dobry kontakt, cierpliwość, solidność w każdym calu, budowanie wspólnej wizji. Jestem bardzo zadowolony ze współpracy z Panem Markiem. Polecam!
      `,
      reviewerName: 'Janusz Tomzik',
      rating: '5.0',
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            <div className="avatar">
              {val.firstLetter}
              {/* <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img> */}
            </div>
            {/* End avatar */}
            <div className="testmonial-text media-body">
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                <p>{val.opinions}</p>
                <div className="testimonial-stars-row">
                  <div className="stars">
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                  </div>
                  <div className="date">{val.date}</div>
                  <div className="new-opinion">Nowy</div>
                </div>
              </div>
              <p className="desc">{val.desc}</p>
            </div>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}

import React, { useContext } from 'react';
import NotFound from '../views/NotFound';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';
import LanguageContext from '../providers/langContext';
import WebsitesPage from '../views/WebsitesPage';
import SeoPage from '../views/SeoPage';
import CreateBrand from '../views/CreateBrand';
import VuePage from '../views/VuePage';
import ReactPage from '../views/ReactPage';
import PhalconPage from '../views/PhalconPage';
import PrivacyPolicy from '../views/PrivacyPolicy';

const Routes = () => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/">
            {language.includes('pl') ? (
              <Redirect to="/pl/strony-internetowe" />
            ) : (
              <Redirect to="/en/vue" />
            )}
          </Route>

          <Route exact path="/pl/strony-internetowe" component={WebsitesPage} />
          <Route exact path="/pl/pozycjonowanie" component={SeoPage} />
          <Route
            exact
            path="/pl/identyfikacja-wizualna"
            component={CreateBrand}
          />
          <Route exact path="/polityka-prywatnosci" component={PrivacyPolicy} />
          <Route exact path="/en" component={VuePage} />
          <Route exact path="/en/vue" component={VuePage} />
          <Route exact path="/en/react" component={ReactPage} />
          <Route exact path="/en/phalcon" component={PhalconPage} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;

import React from 'react';
import Contact from '../components/contact/Contact';
import AddressData from 'components/contact/AddressData';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Header />

      <div
        style={{ background: '#1c1d24' }}
        className="privacy-policy-header section light-grey"
      >
        <div className="container d-flex align-items-end">
          <h3 style={{ color: 'white', marginTop: 'auto' }}>
            <span>Polityka</span> prywatności
          </h3>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ol className="cookiepolicy">
              <li>
                <h3 className="mt-5">Zakres Polityki prywatności i cookies</h3>

                <p>
                  Administratorem danych jest firma SHOVV Marek Stankiewicz:
                  15-062 Białystok, ul. Warszawska 21, lok 115, posiadająca NIP
                  966-202-29-85 (dalej „firma”, „my” lub „nas”).
                </p>
                <p>
                  Zobowiązujemy się postępować właściwie, jeśli chodzi o
                  zbieranie, wykorzystywanie i ochronę danych osobowych. Chcemy,
                  abyś był pewny, że Twoje dane są z nami bezpieczne a także w
                  pełni rozumiał, w jaki sposób je wykorzystujemy, aby
                  zaoferować Ci lepsze i bardziej spersonalizowane usługi.
                  Właśnie dlatego została opracowana ta Polityka prywatności i
                  cookies (dalej "Polityka"), która:
                </p>
                <ul>
                  <li>określa rodzaje danych osobowych, które gromadzimy;</li>
                  <li>
                    wyjaśnia, w jaki sposób i dlaczego zbieramy i wykorzystujemy
                    Twoje dane osobowe;
                  </li>
                  <li>
                    wyjaśnia, kiedy i dlaczego udostępnimy Twoje dane osobowe
                    innym organizacjom;
                  </li>
                  <li>
                    wyjaśnia prawa i wybory, które masz, jeśli chodzi o Twoje
                    dane osobowe.
                  </li>
                </ul>
                <p>
                  W związku z oferowaną gamą usług, chcemy, abyś jasno wiedział,
                  co obejmuje ta Polityka. Niniejsza Polityka dotyczy Ciebie,
                  jeśli korzystasz z naszych usług (określonych w niniejszej
                  Polityce jako "nasze Usługi"). Korzystanie z naszych Usług
                  oznacza:
                </p>
                <ul>
                  <li>
                    Odwiedzenie naszego Serwisu oraz korzystanie z naszych Usług
                    oferowanych w ramach jego funkcjonalności;
                  </li>
                  <li>
                    Niniejsza Polityka ma również zastosowanie, jeśli
                    skontaktujesz się z nami lub skontaktujemy się z Tobą w
                    sprawie naszych Usług;
                  </li>
                  <li>
                    Wybrane części niniejszej Polityki obowiązują również w
                    naszych biurach stacjonarnych, w których za pomocą
                    monitoringu CCTV rejestrowane są Twoje zdjęcia.
                  </li>
                </ul>
                <p>
                  Nasze Serwisy lub aplikacje mobilne mogą zawierać linki do
                  innych stron internetowych obsługiwanych przez inne podmioty,
                  które mają własne zasady i polityki prywatności, które
                  wyjaśniają, w jaki sposób wykorzystują Twoje dane osobowe. W
                  takich sytuacjach prosimy o dokładne zapoznanie się z
                  rzeczonymi warunkami i politykami prywatności przed podaniem
                  jakichkolwiek danych osobowych na takiej stronie internetowej,
                  ponieważ nie ponosimy żadnej odpowiedzialności za strony
                  internetowe innych podmiotów.
                </p>
              </li>
              <li>
                <h3>Dane osobowe które zbieramy</h3>

                <p>
                  Ta sekcja zawiera informacje o danych osobowych, które możemy
                  od Ciebie gromadzić podczas korzystania z naszych Usług oraz
                  innych danych osobowych, które możemy otrzymać z innych
                  źródeł.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Przeglądając nasze Serwisy lub korzystając z naszych
                        aplikacji mobilnych, możemy zbierać:
                      </td>
                      <td>
                        <ul>
                          <li>
                            Informacje o sposobie przeglądania naszych Serwisów
                            i aplikacji mobilnych oraz informacje o kliknięciu
                            jednej z naszych reklam (w tym na stronach
                            internetowych innych organizacji);
                          </li>
                          <li>
                            Informacje o wszelkich urządzeniach używanych do
                            uzyskania dostępu do naszych Usług (w tym marka,
                            model i system operacyjny, adres IP, typ
                            przeglądarki i identyfikatory urządzeń mobilnych);
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Kontaktując się z nami, lub kiedy my kontaktujemy się z
                        Tobą:
                      </td>
                      <td>
                        <ul>
                          <li>
                            Dane osobowe, które podajesz na swój temat, za
                            każdym razem, kiedy kontaktujesz się z nami w
                            sprawie naszych Usług (na przykład Twoje imię,
                            nazwisko, dane kontaktowe), w tym przez telefon,
                            e-mail lub pocztę lub podczas rozmowy z nami za
                            pośrednictwem mediów społecznościowych;
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Kiedy odwiedzasz nasze biura regionalne:</td>
                      <td>
                        <ul>
                          <li>
                            Twoje zdjęcia w ramach naszych systemów monitoringu
                            CCTV
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <h3>Inne źródła danych osobowych</h3>
                <p> </p>
                <p>
                  Możemy również wykorzystywać dane osobowe z innych źródeł jak:
                  specjalistyczne firmy, które dostarczają informacje, nasi
                  partnerzy handlowi i rejestry publiczne. Tego typu dane
                  osobowe pomagają nam m.in.:
                </p>
                <ul>
                  <li>
                    dokonać przeglądu i poprawić dokładność posiadanych przez
                    nas danych.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Jak i dlaczego używany danych osobowych</h3>
                <p> </p>
                <p>
                  W poniższej tabeli szczegółowo wyjaśniamy w jaki sposób i
                  dlaczego wykorzystujemy dane osobowe:
                </p>
                <table className="hide-for-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <th>Podstawa prawna</th>
                    </tr>
                    <tr>
                      <td rowSpan="3">
                        <strong>
                          Zarządzania i usprawniania naszych codziennych
                          operacji biznesowych
                        </strong>
                      </td>
                      <td
                        style={{
                          width: '25%',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        }}
                      >
                        Zarządzanie i ulepszanie naszych Serwisów i aplikacji
                        mobilnych.
                      </td>
                      <td
                        style={{
                          width: '40%',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        }}
                      >
                        Używamy plików cookies i podobnych technologii w naszych
                        Serwisach i w aplikacjach mobilnych, aby poprawić jakość
                        obsługi klienta. Niektóre pliki cookies są niezbędne,
                        więc nie należy ich wyłączać, aby móc korzystać ze
                        wszystkich funkcji naszych Serwisów i aplikacji
                        mobilnych. Możesz wyłączyć inne pliki cookies, ale może
                        to wpłynąć na Twoje odczucia z korzystania z naszych
                        witryn. Więcej informacji o plikach cookies i sposobach
                        ich wyłączenia można znaleźć w sekcji Pliki cookies i
                        podobne technologie.
                      </td>
                      <td rowSpan="3">
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Rozwijania i ulepszania naszej oferty usług, know-how i
                        sposobu, w jaki komunikujemy się z Tobą.
                      </td>
                      <td>
                        Opieramy się na wykorzystaniu danych osobowych do
                        przeprowadzania badań rynkowych, a także do ulepszania
                        naszych systemów informatycznych (w tym bezpieczeństwa)
                        oraz naszych usług. Dzięki temu możemy służyć Ci lepiej
                        jako klientowi.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Wykrywania oszustw i innych przestępstw oraz ich
                        zapobiegania.
                      </td>
                      <td>
                        Ważne jest, abyśmy monitorowali nasze Usługi w
                        kontekście wykrywania i zapobiegania oszustwom, innym
                        przestępstwom i niewłaściwemu wykorzystywaniu usług.
                        Pomoże nam to upewnić się, że możesz bezpiecznie
                        korzystać z naszych Usług.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Personalizowania usług pod Twoje oczekiwania
                        </strong>
                      </td>
                      <td>
                        Analizowania Twoich nawyków przeglądania naszych
                        Serwisów, aby móc lepiej zrozumieć Cię jako naszego
                        użytkownika oraz zapewnić Ci spersonalizowane usługi.
                      </td>
                      <td>
                        Przeglądanie Twoich zachowań pozwala nam personalizować
                        nasze usługi. Pomoże nam to spełnić Twoje potrzeby jako
                        użytkownika.
                      </td>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Kontaktu i interakcji z Tobą</strong>
                      </td>
                      <td>
                        Kontaktu z Tobą w sprawie naszych Usług, na przykład
                        przez telefon,
                        <br />
                        e-mail lub pocztę, lub odpowiadając na posty w mediach
                        społecznościowych, które skierowałeś do nas.
                      </td>
                      <td>
                        Chcemy służyć Ci najlepiej jako klientowi, więc
                        wykorzystujemy dane osobowe w celu udzielenia wyjaśnień
                        lub pomocy w odpowiedzi na Twoją komunikację.
                      </td>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Monitoring CCTV</strong>
                      </td>
                      <td>
                        Monitorowania bezpieczeństwa naszych biur, aby
                        zapobiegać przestępstwom i innym niepożądanym
                        zrachowaniom.
                      </td>
                      <td>
                        Aby chronić nasz biznes, lokalną społeczność, klientów i
                        współpracowników.
                      </td>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>
                          Zarządzania i usprawniania naszych codziennych
                          operacji biznesowych
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Zarządzanie i ulepszanie naszych Serwisów i aplikacji
                        mobilnych.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Używamy plików cookies i podobnych technologii w naszych
                        Serwisach i w aplikacjach mobilnych, aby poprawić jakość
                        obsługi klienta. Niektóre pliki cookies są niezbędne,
                        więc nie należy ich wyłączać, aby móc korzystać ze
                        wszystkich funkcji naszych Serwisów i aplikacji
                        mobilnych. Możesz wyłączyć inne pliki cookies, ale może
                        to wpłynąć na Twoje odczucia z korzystania z naszych
                        witryn. Więcej informacji o plikach cookies i sposobach
                        ich wyłączenia można znaleźć w sekcji Pliki cookies i
                        podobne technologie.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>
                          Zarządzania i usprawniania naszych codziennych
                          operacji biznesowych
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Rozwijania i ulepszania naszej oferty usług, know-how i
                        sposobu, w jaki komunikujemy się z Tobą.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Opieramy się na wykorzystaniu danych osobowych do
                        przeprowadzania badań rynkowych, a także do ulepszania
                        naszych systemów informatycznych (w tym bezpieczeństwa)
                        oraz naszych usług. Dzięki temu możemy służyć Ci lepiej
                        jako klientowi.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>
                          Zarządzania i usprawniania naszych codziennych
                          operacji biznesowych
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Wykrywania oszustw i innych przestępstw oraz ich
                        zapobiegania.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Ważne jest, abyśmy monitorowali nasze Usługi w
                        kontekście wykrywania i zapobiegania oszustwom, innym
                        przestępstwom i niewłaściwemu wykorzystywaniu usług.
                        Pomoże nam to upewnić się, że możesz bezpiecznie
                        korzystać z naszych Usług.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>
                          Personalizowania usług pod Twoje oczekiwania
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Analizowania Twoich nawyków przeglądania naszych
                        Serwisów, aby móc lepiej zrozumieć Cię jako naszego
                        użytkownika oraz zapewnić Ci spersonalizowane usługi.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Przeglądanie Twoich zachowań pozwala nam personalizować
                        nasze usługi. Pomoże nam to spełnić Twoje potrzeby jako
                        użytkownika.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>Kontaktu i interakcji z Tobą</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Kontaktu z Tobą w sprawie naszych Usług, na przykład
                        przez telefon, e-mail lub pocztę, lub odpowiadając na
                        posty w mediach społecznościowych, które skierowałeś do
                        nas.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Chcemy służyć Ci najlepiej jako klientowi, więc
                        wykorzystujemy dane osobowe w celu udzielenia wyjaśnień
                        lub pomocy w odpowiedzi na Twoją komunikację.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>Używamy danych osobowych do:</th>
                      <td>
                        <strong>Monitoring CCTV</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        To oznacza, że przetwarzanie Twoich danych osobowych
                        pozwala nam na:
                      </th>
                      <td>
                        Monitorowania bezpieczeństwa naszych biur, aby
                        zapobiegać przestępstwom i innym niepożądanym
                        zrachowaniom.
                      </td>
                    </tr>
                    <tr>
                      <th>Dlaczego przetwarzamy Twoje dane w ten sposób?</th>
                      <td>
                        Aby chronić nasz biznes, lokalną społeczność, klientów i
                        współpracowników.
                      </td>
                    </tr>
                    <tr>
                      <th>Podstawa prawna</th>
                      <td>
                        <strong>Prawnie usprawiedliwiony cel</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <h3>
                  Nasze prawnie usprawiedliwione cele w używaniu Twoich danych
                  osobowych
                </h3>
                <p> </p>
                <p>
                  W miejscach w których wspomnieliśmy powyżej, że wykorzystanie
                  danych osobowych opiera się na naszym "prawnie
                  usprawiedliwionym celu", są to:
                </p>
                <ul>
                  <li>
                    obsługa potrzeb naszych klientów, w tym dostarczania naszych
                    usług;
                  </li>
                  <li>promowanie i wprowadzania na rynek naszych usług;</li>
                  <li>
                    obsługa konta (na przykład konto użytkownika online),
                    zarządzanie skargami i rozwiązywania wszelkich sporów;
                  </li>
                  <li>
                    zrozumienie naszych użytkowników, w tym ich wzorców,
                    zachowania, a także ich upodobań i preferencji;
                  </li>
                  <li>
                    ochrona i wsparcie naszej działalności, współpracowników,
                    użytkowników i współwłaścicieli;
                  </li>
                  <li>
                    testowanie i rozwijanie nowych usług, a także ulepszanie już
                    istniejących;
                  </li>
                  <li>wymogi prawne / regulacyjne.</li>
                </ul>
              </li>
              <li>
                <h3>
                  W jaki sposób i dlaczego udostępniamy dane osobowe dostawcom
                  usług
                </h3>
                <p> </p>
                <p>
                  W tej sekcji wyjaśniamy w jaki sposób i dlaczego udostępniamy
                  dane osobowe dostawcom usług. Kiedy udostępniamy dane osobowe
                  tym firmom, wymagamy od nich zachowania bezpieczeństwa i nie
                  wolno im wykorzystywać danych osobowych do własnych celów
                  marketingowych.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Dostawcy usług</strong>
                      </td>
                      <td>
                        Współpracujemy ze starannie wybranymi dostawcami usług,
                        którzy realizują określone funkcje w naszym imieniu.
                        Należą do nich na przykład firmy, które pomagają nam w
                        świadczeniu: usług dla klientów, usług technologicznych,
                        przechowywaniu i łączeniu danych, przetwarzaniu
                        płatności i dostarczaniu zamówień. Udostępniamy tylko
                        dane osobowe, które umożliwiają naszym dostawcom
                        świadczenie tych usług.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <h3>
                  W jaki sposób i dlaczego udostępniamy dane osobowe innym
                  podmiotom
                </h3>
                <p> </p>
                <p>
                  Możemy udostępniać dane osobowe innym organizacjom w
                  następujących okolicznościach:
                </p>
                <ul>
                  <li>
                    gdy prawo lub organ publiczny twierdzi, że musimy udostępnić
                    dane osobowe;
                  </li>
                  <li>
                    gdy musimy udostępnić dane osobowe w celu ustalenia,
                    wykonania lub obrony naszych praw (w tym przekazywania
                    danych osobowych innym osobom w celu zapobiegania oszustwom
                    i zmniejszania ryzyka kredytowego);
                  </li>
                  <li>
                    do organizacji, której sprzedajemy lub przenosimy (lub
                    przystępujemy do negocjacji w celu sprzedaży lub
                    przeniesienia) któregokolwiek z naszych praw lub obowiązków
                    wynikających z jakiejkolwiek umowy, którą możemy mieć z Tobą
                    zawartą. Jeśli przeniesienie lub sprzedaż się powiedzie,
                    podmiot otrzymujący Twoje dane osobowe może wykorzystywać
                    twoje dane osobowe w taki sam sposób jak my;
                  </li>
                  <li>
                    do wszelkich następców prawnych związanych z naszą
                    działalnością.
                  </li>
                </ul>
              </li>
              <li>
                <h3>W jaki sposób chronimy Twoje dane osobowe</h3>
                <p> </p>
                <p>
                  Wiemy, jak ważne jest odpowiednie zabezpieczenie i zarządzanie
                  danymi osobowymi. Ta sekcja przedstawia wybrane z
                  wprowadzonych przez nas środków. Aby zapewnić bezpieczeństwo
                  Twoich danych używamy zabezpieczeń komputerowych, takich jak:
                </p>
                <ul>
                  <li>
                    ograniczenie dostępu jedynie dla pracowników, którzy
                    potrzebują go do wykonywania swoich obowiązków służbowych;
                  </li>
                  <li>
                    egzekwujemy fizyczne, elektroniczne i proceduralne
                    zabezpieczenia związane z gromadzeniem, przechowywaniem i
                    ujawnianiem danych osobowych;
                  </li>
                  <li>
                    fizyczne kontrole dostępu do naszych budynków i plików;
                  </li>
                  <li>
                    zapory sieciowe i szyfrowanie danych podczas przesyłu za
                    pomocą protokołu Secure Sockets Layer (SSL).
                  </li>
                </ul>
                <p>
                  Jednak mimo podjęcia odpowiednich środków technicznych i
                  organizacyjnych w celu ochrony danych osobowych, nie możemy
                  zagwarantować bezpieczeństwa jakichkolwiek danych osobowych
                  przesyłanych przez Internet do nas.
                </p>
                <p>
                  Dane osobowe, które od Państwa zbieramy, mogą być przekazywane
                  i przechowywane w miejscu docelowym poza Europejskim Obszarem
                  Gospodarczym ("EOG"). Mogą być również przetwarzane przez
                  firmy działające poza EOG, które pracują dla nas lub dla
                  jednego z naszych dostawców usług. Jeśli to zrobimy,
                  zapewniamy poszanowanie Twoich praw prywatności zgodnie z
                  niniejszymi Zasadami. Najczęstszym sposobem, w jaki to robimy,
                  jest wprowadzenie konkretnego rodzaju umowy (więcej informacji
                  nt. temat można znaleźć tutaj:
                  https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_en)
                  lub poprzez zatwierdzony program, taki jak Tarcza Prywatności
                  <span className="hide-for-mobile">
                    : https://www.privacyshield.gov/welcome.
                  </span>
                </p>
              </li>
              <li>
                <h3>Jak długo przetwarzamy dane osobowe</h3>
                <p> </p>
                <p>
                  Nie będziemy przechowywać Twoich danych osobowych dłużej niż
                  potrzebujemy, co jest uzależnione od kilku czynników:
                </p>
                <ol className="type-a">
                  <li>
                    (Przede wszystkim) powodu dla którego je zgromadziliśmy;
                  </li>
                  <li>Jak dawno temu zostały zebrane;</li>
                  <li>
                    Czy istnieje podstawa prawna / regulacyjna, abyśmy je
                    zachowali;
                  </li>
                  <li>
                    Czy ich potrzebujemy, aby chronić Twojego lub naszego
                    interesu.
                  </li>
                </ol>
              </li>
              <li>
                <h3>Jak wykorzystujemy pliki cookies</h3>
                <p> </p>
                <p>
                  Pliki cookies (tzw. "ciasteczka") stanowią dane informatyczne
                  – w szczególności pliki tekstowe – są umieszczane przez nasz
                  Serwis podczas każdej wizyty w urządzeniu końcowym
                  Użytkownika, co pozwala naszym witrynom internetowym
                  zapamiętać komputer Użytkownika lub urządzenie oraz służy
                  realizacji kilku celów. Podmiotem zamieszczającym na
                  urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz
                  uzyskującym do nich dostęp jest firma. Serwer Serwisu
                  automatycznie rejestruje informacje przesyłane przez
                  przeglądarkę Użytkownika w czasie wyświetlania witryny.
                  Dzienniki serwera mogą zawierać informacje takie jak żądanie
                  sieciowe, adres IP, typ i język przeglądarki, data i godzina
                  przesłania żądania. Te informacje pozwalają podnosić jakość
                  naszych Usług dzięki identyfikowaniu i przechowywaniu
                  preferencji Użytkownika oraz śledzeniu trendów, takich jak na
                  przykład sposoby w jakich przeszukiwane są nasze Serwisy.
                </p>
                <p>
                  Na naszych Serwisach wykorzystywane są następujące rodzaje
                  plików cookies:
                </p>
                <table className="hide-for-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <th>CHARAKTERYSTYKA</th>
                      <th>TYP</th>
                    </tr>
                    <tr>
                      <td>
                        <strong>Zapewnienie wydajności</strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            kompatybilność (np. identyfikacja typu
                            przeglądarki);
                          </li>
                          <li>
                            optymalizacja (np. pomiar czasu ładowania zawartości
                            Serwisu);
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Zwiększenie bezpieczeństa</strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            sprawdzenie czy urządzenie użytkownika jest
                            bezpiecznie zalogowane przez cały czas trwania jego
                            wizyty w Serwisie;
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Zapamiętanie preferencji</strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            ulepszenie działania Serwisu np. poprzez
                            spersonalizowane treści, powitanie lub zapamiętanie
                            wybranego języka;
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Analiza sposobu korzystania z Serwisu</strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            zbieranie statystyk dotyczących m.in. ogólnej liczby
                            wizyt odsłon oraz odwołań do Serwisu;
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Informacje zwrotne od Użytkowników Serwisu
                        </strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            nie wyświetlanie powiadomień o charakterze
                            jednorazowym po przejściu Użytkownika do kolejnej
                            podstrony Serwisu;
                          </li>
                          <li>
                            nie wyświetlanie powiadomień cyklicznych przez
                            zdefiniowany okres;
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                          <br />
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Wtyczki / widgety</strong>
                      </td>
                      <td>
                        <ul>
                          <li>
                            dzielenie się treściami Serwisu na platformach
                            społecznościowych;
                          </li>
                          <li>
                            odnotowywanie interakcji użytkowników w Serwisie
                            (np. za pomocą licznika liczby udostępnień);
                          </li>
                        </ul>
                      </td>
                      <td>
                        <em>
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>Zapewnienie wydajności</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            kompatybilność (np. identyfikacja typu
                            przeglądarki);
                          </li>
                          <li>
                            optymalizacja (np. pomiar czasu ładowania zawartości
                            Serwisu);
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>Zwiększenie bezpieczeństa</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            sprawdzenie czy urządzenie użytkownika jest
                            bezpiecznie zalogowane przez cały czas trwania jego
                            wizyty w Serwisie;
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>Zapamiętanie preferencji</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            ulepszenie działania Serwisu np. poprzez
                            spersonalizowane treści, powitanie lub zapamiętanie
                            wybranego języka;
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>Analiza sposobu korzystania z Serwisu</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            zbieranie statystyk dotyczących m.in. ogólnej liczby
                            wizyt odsłon oraz odwołań do Serwisu;
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>
                          Informacje zwrotne od Użytkowników Serwisu
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            nie wyświetlanie powiadomień o charakterze
                            jednorazowym po przejściu Użytkownika do kolejnej
                            podstrony Serwisu;
                          </li>
                          <li>
                            nie wyświetlanie powiadomień cyklicznych przez
                            zdefiniowany okres;
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Sesyjne -&gt; w chwili zamknięcia przeglądarki
                          ciasteczka są usuwane
                          <br />
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="only-mobile">
                  <tbody>
                    <tr>
                      <th>DZIAŁANIE</th>
                      <td>
                        <strong>Wtyczki / widgety</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>CHARAKTERYSTYKA</th>
                      <td>
                        <ul>
                          <li>
                            dzielenie się treściami Serwisu na platformach
                            społecznościowych;
                          </li>
                          <li>
                            odnotowywanie interakcji użytkowników w Serwisie
                            (np. za pomocą licznika liczby udostępnień);
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>TYP</th>
                      <td>
                        <em>
                          Stałe -&gt; usuwane po dłuższym braku odwiedzania
                          Serwisu
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                  Serwisu wykorzystywane mogą być również przez strony trzecie w
                  celu zaoferowania użytkownikom dodatkowych funkcjonalności.
                  Korzystanie z powyższych narzędzi lub widgetów może powodować
                  gromadzenie plików cookies na urządzeniach użytkowników w celu
                  ułatwienia korzystania z tych serwisów i zapewnienia, że
                  interakcja użytkowników jest wyświetlana poprawnie w naszych
                  Serwisach.
                </p>
              </li>
              <li>
                <h3>Twój wybór w zakresie plików cookies</h3>
                <p> </p>
                <p>
                  Pomimo, że większość przeglądarek internetowych automatycznie
                  zezwala na umieszczanie na komputerze plików cookies,
                  Użytkownik może zabronić odbierania plików cookies, przez co
                  pozostanie anonimowy. W takim przypadku należy pamiętać, że
                  nie wyrażając zgody na umieszczanie plików cookies, użytkownik
                  nie będzie mógł w pełni korzystać ze wszystkich funkcji
                  Serwisu. W celu skonfigurowania opcji swojego urządzenia w
                  zakresie wyrażenia zgody na zapisywanie plików cookies oraz
                  określenia zakresu zapisywanych cookies, Użytkownik może
                  dokonać zmian ustawień wykorzystywanej przeglądarki
                  internetowej (w większości przypadków taka opcja znajduje się
                  w Narzędziach lub menu Preferencje przeglądarki).
                </p>
                <p>
                  Informujemy, iż niedokonanie przez Użytkownika zmiany ustawień
                  w zakresie cookies oznacza, że będą one zamieszczone w
                  urządzeniu końcowym Użytkownika. W takim przypadku nasze
                  Serwisy mogą przechowywać informacje w urządzeniu końcowym
                  Użytkownika i uzyskiwać dostęp do tych informacji.
                </p>
                <p>
                  Informacje na temat zarządzania plikami cookies w
                  poszczególnych przeglądarkach można znaleźć na stronach
                  dedykowanych poszczególnym przeglądarkom:
                </p>
                <ul>
                  <li>Firefox: https://support.mozilla.org/pl/kb/ciasteczka</li>
                  <li>
                    IE:
                    https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies
                  </li>
                  <li>
                    Chrome: https://support.google.com/chrome/answer/95647?hl=pl
                  </li>
                  <li>
                    Opera: http://help.opera.com/Linux/12.10/pl/cookies.html
                  </li>
                  <li>Safari: https://support.apple.com/pl-pl/HT201265</li>
                </ul>
                <p>
                  Dodatkowe informacje o zarządzaniu plikami cookies znajdują
                  się na takich stronach jak np. http://allaboutcookies.org
                </p>
              </li>
              <li>
                <h3>Prawo dostępu do danych</h3>
                <p> </p>
                <p>
                  Masz prawo do wglądu w swoje dane osobowe, które
                  przechowujemy.
                </p>
              </li>
              <li>
                <h3>Pozostałe prawa osób, których dane są przetwarzane</h3>
                <p> </p>
                <p>W odniesieniu do Twoich danych osobowych masz również:</p>
                <ol>
                  <li>
                    <h3>Prawo do poprawienia danych</h3>
                    <p> </p>
                    <p>
                      Jeśli uważasz, że posiadamy niedokładne lub brakujące
                      informacje na Twój temat, daj nam znać, a my to poprawimy.
                    </p>
                  </li>
                  <li>
                    <h3>Prawo do sprzeciwu</h3>
                    <p> </p>
                    <p>
                      <strong>Sprzeciw ogólny</strong> – rozpatrzymy sprzeciw
                      wobec wykorzystania przez nas Twoich danych osobowych.
                      Jeśli Twoje prawa przeważają nad naszymi interesami w
                      korzystaniu z Twoich danych osobowych, wtedy na Twoją
                      prośbę albo ograniczymy ich przetwarzanie (patrz sekcja
                      13.3 poniżej) albo usuniemy je (patrz sekcja 13.4
                      poniżej).
                    </p>
                  </li>
                  <li>
                    <h3>Prawo do ograniczenia przetwarzania danych</h3>
                    <p> </p>
                    <p>
                      Istnieje kilka sytuacji, w których można ograniczyć
                      korzystanie z danych osobowych, w tym (ale nie tylko):
                    </p>
                    <ul>
                      <li>
                        złożyłeś ogólny sprzeciw (wymieniony w punkcie 13.2
                        powyżej);
                      </li>
                      <li>
                        kwestionujesz dokładność danych osobowych, które
                        przechowujemy;
                      </li>
                      <li>
                        wykorzystaliśmy Twoje dane osobowe bezprawnie, ale nie
                        chcesz, abyśmy je usunęli.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Prawo do usunięcia danych</h3>
                    <p> </p>
                    <p>
                      Istnieje kilka sytuacji, w których możesz zlecić nam
                      usunięcie Twoich danych osobowych, w tym (ale nie tylko):
                    </p>
                    <ul>
                      <li>
                        nie musimy już przechowywać Twoich danych osobowych;
                      </li>
                      <li>
                        z powodzeniem złożyłeś sprzeciw ogólny (wymieniony w
                        punkcie 13.2 powyżej);
                      </li>
                      <li>
                        wycofaliście Państwo zgodę na wykorzystywanie przez nas
                        Państwa danych osobowych (i nie mamy żadnych innych
                        powodów, aby z nich korzystać);
                      </li>
                      <li>bezprawnie przetworzyliśmy Twoje dane osobowe.</li>
                    </ul>
                  </li>
                  <li>
                    <h3>Prawo do przenoszenia danych</h3>
                    <p> </p>
                    <p>
                      Jeśli chcesz otrzymać kopię danych osobowych, które
                      posiadamy o Tobie, skontaktuj się z nami za pośrednictwem
                      adresu: hello@shovv.pl
                    </p>
                  </li>
                  <li>
                    <h3>Prawo do wniesienia skargi do organu nadzorczego</h3>
                    <p> </p>
                    <p>
                      Chcielibyśmy móc rozwiązać wszelkie Państwa wnioski i
                      uwagi, jednakże mają Państwo również prawo złożyć skargę
                      bezpośrednio lokalnemu organowi nadzorczemu jeśli
                      uważacie, że przetwarzamy dane osobowe niezgodnie z
                      prawem.
                    </p>
                  </li>
                  <li>
                    <h3>
                      Więcej informacji na temat twoich praw do ochrony danych
                    </h3>
                    <p> </p>
                    <p>
                      Strona internetowa Prezesa Urzędu Ochrony Danych Osobowych
                      (PUODO) dotycząca ochrony danych zawiera więcej
                      szczegółowych informacji na temat wspomnianych wyżej
                      Państwa praw do ochrony danych osobowych. Jeśli chcesz
                      bardziej szczegółowo porozmawiać z nami na temat tych
                      praw, skorzystaj z sekcji poniżej w której informujemy
                      "jak się z nami skontaktować".
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>Jak się z nami skontaktować?</h3>
                <p> </p>
                <p>
                  Jeśli masz pytania dotyczące sposobu gromadzenia,
                  przechowywania i wykorzystywania danych osobowych, skontaktuj
                  się z nami: <br />
                  Telefon: +48 663 342 150
                  <br />
                  E-mail: hello@shovv.pl
                  <br />
                  Poczta: ul. Warszawska 21 lok. 115, 15-062 Białystok
                </p>
              </li>
            </ol>
            <p>
              Polityka prywatności została ostatnio zaktualizowana{' '}
              <time>2022-14-01</time>.
            </p>
          </div>
        </div>
      </div>

      {/* Contact */}
      <section id="contact" className="section after-left-section ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
};

export default PrivacyPolicy;

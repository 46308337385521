import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Benefits from 'components/benefits/Benefits';
import Header from '../components/header/Header-en';
import Contact from '../components/contact/ContactEN';
import Footer from '../components/footer/Footer';
import Testimonial from '../components/testimonial/Testimonial';
import imgJPG from 'assets/images/sea.jpg';
import imgWebp from 'assets/images/sea.webp';
import Brief from '../components/brief-form/BriefEN';
import { checkWebPSupport } from 'supports-webp-sync';
import Social from 'components/Social';
import AddressData from 'components/contact/AddressData';
import { useLocation } from 'react-router-dom';
import LanguageContext from 'providers/langContext';

const VuePage = () => {
  const supportWebp = checkWebPSupport();
  const location = useLocation();
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (location.pathname.includes('/en/')) {
      setLanguage('en');
    }
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Vue.js Development Services for your project | SHOVV</title>
        <meta
          name="description"
          content="Hire Vue.js developers to build your web application from scratch. Looking for outsourcing your Vue.js product to professional company? Contact us."
        />
        <link rel="canonical" href="https://shovv.pl/en/vue" />

        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Vue.js Development Services for your project | SHOVV"
        />
        <meta
          property="og:description"
          content="Hire Vue.js developers to build your web application from scratch. Looking for outsourcing your Vue.js product to professional company? Contact us."
        />
        <meta property="og:url" content="https://shovv.pl/en/vue" />
        <meta
          property="og:site_name"
          content="Vue.js Development Services for your project | SHOVV"
        />
        <meta property="og:image" content="https://shovv.pl/img/hero-en.jpg" />
        <meta
          property="og:image:secure_url"
          content="https://shovv.pl/img/hero-en.jpg"
        />
        <meta property="og:image:width" content="1622px" />
        <meta property="og:image:height" content="1081px" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Hire Vue.js developers to build your web application from scratch. Looking for outsourcing your Vue.js product to professional company? Contact us."
        />
        <meta
          name="twitter:title"
          content="Vue.js Development Services for your project | SHOVV"
        />
        <meta name="twitter:image" content="https://shovv.pl/img/hero-en.jpg" />

        <script
          type="application/ld+json"
          data-react-helmet="true"
          data-rh="true"
        >
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SHOVV",
            "url": "https://shovv.pl/",
            "logo": "https://shovv.pl/static/media/shovv-logo-black.c0082665.svg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+48663342150",
              "contactType": "customer service",
              "areaServed": ["150","PL","GB","US"],
              "availableLanguage": ["Polish","en"]
            },
            "sameAs": [
              "https://www.facebook.com/shovvpl/",
              "https://www.instagram.com/shovvpl/"
            ]
          }
          `}
        </script>
      </Helmet>
      <Header />

      <section
        className="section preview-section"
        style={
          supportWebp
            ? {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero-en.webp'
                })`,
              }
            : {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero-en.jpg'
                })`,
              }
        }
      >
        <div className="scroll-bottom go-to">
          <a href="#about">Scroll</a>
        </div>
        <Social />
      </section>

      {/* SEA IMAGES SECTION */}
      <section className="rectangles-section section light-grey pt-0">
        <div className="container ">
          <div className="row justify-content-center">
            <div id="about" className="col-12 col-lg-8 bg-white white-part">
              <h1 className="px-3 mb-4 mx-lg-4">
                <span> DEVELOPING HIGH PERFORMANCE </span>
                APPS WITH VUE JS
              </h1>
              <p className="px-3 mb-3">
                Developing high performance web application can be challenging.
                One of the challenges can be choosing right framework for your
                project. Angular? React? Vue? Backbone? There is a lot
                frameworks. Wrong choice can have dramatic impact on developing
                costs and quality of final product.
              </p>
              <p className="px-3 mb-3">
                Vue.js takes what's bests of frameworks like Angular and React
                and provide a lot of features in lightweight package. Thanks to
                them we can develop high performance web app. As final result
                you will have a product that users love.
              </p>

              <p className="px-3 mb-3">
                In 2022 Vue.js is used by Facebook, Netflix, Adobe, Behance,
                Apple and many more...
              </p>
            </div>
          </div>
        </div>
        <div className="container featured-content ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="Single Page Application Vue"
                />
              </picture>
            </div>
          </div>
          <div className="row justify-content-center featured-content__wrapper">
            <div className="col-12 col-lg-4 bg-dark featured-content__text">
              <h3 className="px-3 mb-4">
                <span>Why do we</span> use vue js?
              </h3>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Vue.js is one of the top three most popular JavaScript
                frameworks. It is a perfect choice for project of any size.
              </p>
              <p className="px-3 mb-3">
                According to Google Trends and GitHub Vue.js breaks records in
                popularity.
              </p>
              <p className="px-3 mb-3">
                What makes Vue.js stand out from other frameworks out there?
              </p>
              <p className="px-3 mb-3">
                One of the factors that set Vue.js apart is ease of use and
                freedom of use - use as much framework as you need. Another
                thing is lack of restrictions in development proces unlike
                Angular.
              </p>
              <p className="px-3 mb-3">
                Vue.js also provides great SEO in combination with Nuxt.js,
                thanks to server-side rendering. That will make your web app
                appear higher in the SERP.
              </p>
              <p className="px-3 mb-3">
                You don't have to worry about performance. Vue.js is incredibly
                lightweight
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*END  SEA IMAGES SECTION */}

      {/* FORM SECTION */}
      <section className="brief-form__section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-5">
              <h3 className="px-3 mb-4">
                <span>Complete the form</span> to get free quote
              </h3>
              <p className="px-3">
                We will get to know your business and get to know your
                competitors, and then we will contact you to jointly develop a
                strategy and choose the optimal solution tailored to your needs.
              </p>
              <p className="px-3">
                We respond within 24 hours to questions submitted from Monday to
                Friday, from 9:00 am to 5:00 pm. We respond to other inquiries
                on the next business day.
              </p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5">
              <Brief />
            </div>
          </div>
        </div>
      </section>
      {/*END FORM SECTION */}

      <section className="section light-grey">
        <Benefits />
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>DEVELOPING VUE JS</span> single page applications
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Traditionally web apps included more than one page, linking to
                each other. At each page load there was HTTP request to a
                server.
              </p>
              <p className="px-3 mb-3">
                Single page applications is entirely composed in one web page.
                Request for different pages are handled with JavaScript saving
                bandwidth to replace only parts of the page.
              </p>
              <p className="px-3 mb-3">
                Vue.js appeared couple years ago, but yet it has become on of
                the leading frameworks for building SPAs.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>DEVELOPING VUE JS</span> progressive web applications
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                PWA can be installed directly from browser onto your mobile,
                tablet or a computer. It appears as an icon like a native app.
              </p>
              <p className="px-3 mb-3">
                With progressive web application we can access the features of
                device like bluetooth, nfc, camera and more. Great advantage of
                progressive web applications is ability to work offline.
              </p>
              <p className="px-3 mb-3">
                With PWA you don't have to go through Play Store or App Store.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>EASY</span>
                <br /> INTEGRATIONS
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Vue.js has incrementally adoptable ecosystem, that means it can
                be simply integrated with other libraries. You can embed Vue.js
                into your project without impacting the rest of it.
              </p>
              <p className="px-3 mb-3">
                In comparison to other frameworks Vue.js is very versatile.
                There are no stack demands. It can be implemented to your
                project at any stage you are currently at.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section content-row-center light-grey ">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div className="purchase-block-inner">
                <h3 className="pt-0">
                  <span>VUE JS</span> OUTSOURCING SERVICES
                </h3>
                <p className="px-3">
                  Lack of developers is a common issue for many business.
                  Different projects require different resources. Thats why
                  outsourcing may be a good idea.
                </p>
                <p className="px-3">
                  We develop from scratch advanced web apps using Vue.js. If you
                  are looking for small team of experienced developers to extend
                  your project that is based on Vue.js you can reach us.
                </p>
                <p className="px-3">
                  Our developers will help scale your business at each stage of
                  product development. We provide satisfactory solutions for
                  various industries.
                </p>
                <p className="px-3">
                  Experienced shows us that understanding and good communication
                  are crucial for every successful project. Therefore, we focus
                  primarily on these aspects.
                </p>
              </div>
              {/* End .purchase-block-inner */}
            </div>
          </div>
        </div>
      </section>

      <section className="section our-technologies">
        <div className="container">
          <div className="row px-3">
            <div className="col-12">
              <h3>
                <span>TECHNOLOGIES</span>
                <br />
                we work with
              </h3>
            </div>
            <div className="col-6 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/react.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/react.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/react.webp"
                    type="image/webp"
                    alt="web application react js"
                  />
                </picture>
              </div>
              <h5>React JS</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/vue.webp"
                    type="image/webp"
                  />
                  <source srcSet="/img/technologies/vue.png" type="image/png" />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/vue.webp"
                    type="image/webp"
                    alt="single page application vue js"
                  />
                </picture>
              </div>
              <h5>vue js</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/wordpress.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/wordpress.png"
                    type="image/jpg"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/wordpress.webp"
                    type="image/webp"
                    alt="websites wordpress"
                  />
                </picture>
              </div>
              <h5>wordpress</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <img
                  className="img-fluid w-100"
                  src="/img/technologies/phalcon.svg"
                  type="image/webp"
                  alt="High performance Phalcon websites"
                />
              </div>
              <h5>Phalcon</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="rightSide-section">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-10 offset-lg-4 col-lg-8 rightSide-content">
              <h3 className="px-3">
                <span>WHY CHOOSE</span>
                <br />
                SHOVV?
              </h3>
              <p className="px-3 mb-3">
                SHOVV is software development company that offers narrow range
                of specialized services such as React JS, Vue JS and Phalcon
                PHP. Since 2015 we help our clients to develop web apps across
                various industries.
              </p>
              <p className="px-3 mb-3">
                We provide our customers with affordable pricing options which
                will suit your project budget.
              </p>
              <p className="px-3 mb-3">
                Our developers can join your project at any stage of development
                to work hand-in-hand with your existing team.
              </p>
              <p className="px-3 mb-3">
                In SHOVV we base our development process on agile methodologies
                like scrum to keep you up to date. Constant self-improvement and
                self-organization is a daily thing.
              </p>
              <p className="px-3 mb-3">
                You are free to reach SHOVV developers anytime.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="px-3 px-md-0">
                <span>Hire us for your next</span>
                <br />
                vue js project
              </h3>
              <p className="px-3 px-md-0">
                First step in developing process is understanding your project,
                it's requirements and goals.
              </p>
              <p className="px-3 px-md-0 mb-3">
                With this knowledge, we then draw up a rough blueprint of
                solution for your project.
              </p>
              <p className="px-3 px-md-0 mb-3">
                In the next step we can move to the development. This is where
                we release iterations of your project to gain feedback from
                client. It's a good place to incorporate your suggestions.
              </p>
              <p className="px-3 px-md-0 mb-3">
                We work with clients all over the world, from startups to
                enterprises.
              </p>
              <p className="px-3 px-md-0 mb-3">
                Costs of developing website or web app relay on many factors. We
                apply individual approach to each client. Above you can fill
                form and we will reach you about estimation of your project.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section testimonial">
        <div className="container">
          <div className="row px-3 px-md-0">
            <h3>
              <span>Our clients</span> confirm we deliver
            </h3>
            <Testimonial />
          </div>
        </div>
      </div>
      {/* End testimonial */}

      {/* Contact */}
      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <h3>
                  <span>Contact </span>us
                </h3>

                {/* End .sm-title */}
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default VuePage;

import React from 'react';
import img4 from 'assets/images/benefits/community.svg';
import img6 from 'assets/images/benefits/flexability.svg';
import img3 from 'assets/images/benefits/high-performance.svg';
import img2 from 'assets/images/benefits/quick-development.svg';
import img1 from 'assets/images/benefits/scalability.svg';
import img5 from 'assets/images/benefits/versatility.svg';

const Benefits = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4 col-sm-6 my-3">
          <div className=" d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid " src={img1} alt="scalability" />
            </div>
            <h6 className="benefits-title">scalability</h6>
          </div>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-12 col-lg-4 col-sm-6 my-3">
          <div className="d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid" src={img2} alt="quick development" />
            </div>
            <h6 className="benefits-title">quick development</h6>
            {/* End  */}
          </div>
          {/* End  */}
        </div>
        {/* End .col for blog-1 */}

        <div className="col-12 col-lg-4 col-sm-6 my-3">
          <div className=" d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid" src={img3} alt="high performance" />
            </div>
            <h6 className="benefits-title">high performance</h6>

            {/* End blog-info */}
          </div>
          {/* End  */}
        </div>
        {/* End .col for blog-1 */}

        {/* End .col for blog-1 */}

        <div className="col-12 col-lg-4 col-sm-6 my-3 mt-sm-5">
          <div className=" d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid" src={img4} alt="community" />
            </div>
            <h6 className="benefits-title">community</h6>
            {/* End  */}
          </div>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-12 col-lg-4 col-sm-6 my-3 mt-sm-5">
          <div className=" d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid" src={img5} alt="versatility" />
            </div>
            <h6 className="benefits-title">versatility</h6>
            {/* End  */}
          </div>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-12 col-lg-4 col-sm-6 my-3 mt-sm-5">
          <div className=" d-flex flex-column justify-content-center">
            <div className="benefitImg-box d-flex justify-content-center">
              <img className="img-fluid" src={img6} alt="flexability" />
            </div>
            <h6 className="benefits-title">flexability</h6>
            {/* End  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

import React, { useEffect, useContext } from 'react';
import Header from '../components/header/Header';
import { Helmet } from 'react-helmet';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import OurWebsSection from 'components/cards/WebCards';
import Testimonial from '../components/testimonial/TestimonialPL';
import imgJPG from 'assets/images/sea.jpg';
import imgWebp from 'assets/images/sea.webp';
import Brief from '../components/brief-form/Brief';
import { checkWebPSupport } from 'supports-webp-sync';
import Social from 'components/Social';
import AddressData from 'components/contact/AddressData';
import { useLocation } from 'react-router-dom';
import LanguageContext from 'providers/langContext';

const Websites = () => {
  const supportWebp = checkWebPSupport();
  const location = useLocation();
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (location.pathname.includes('/pl/')) {
      setLanguage('pl');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Strony internetowe dla Adwokatów i Prawników 2022</title>
        <meta
          name="description"
          content="Tworzenie stron internetowych dla kancelarii adwokackich i prawnych. Jeżeli jesteś radcą prawnym, notariuszem lub adwokatem przedstaw ofertę na stronie www."
        />
        <link rel="canonical" href="https://shovv.pl/pl/strony-internetowe" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Strony internetowe dla Adwokatów i Prawników 2022"
        />
        <meta
          property="og:description"
          content="Tworzenie stron internetowych dla kancelarii adwokackich i prawnych. Jeżeli jesteś radcą prawnym, notariuszem lub adwokatem przedstaw ofertę na stronie www."
        />
        <meta
          property="og:url"
          content="https://shovv.pl/pl/strony-internetowe"
        />
        <meta
          property="og:site_name"
          content="Strony internetowe dla Adwokatów i Prawników 2022"
        />
        <meta property="og:image" content="https://shovv.pl/img/hero.jpg" />
        <meta
          property="og:image:secure_url"
          content="https://shovv.pl/img/hero.jpg"
        />
        <meta property="og:image:width" content="1622px" />
        <meta property="og:image:height" content="1081px" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Tworzenie stron internetowych dla kancelarii adwokackich i prawnych. Jeżeli jesteś radcą prawnym, notariuszem lub adwokatem przedstaw ofertę na stronie www."
        />
        <meta
          name="twitter:title"
          content="Strony internetowe dla Adwokatów i Prawników 2022"
        />
        <meta name="twitter:image" content="https://shovv.pl/img/hero.jpg" />

        <script
          type="application/ld+json"
          data-react-helmet="true"
          data-rh="true"
        >
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "SHOVV",
              "url": "https://shovv.pl/",
              "logo": "https://shovv.pl/static/media/shovv-logo-black.c0082665.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+48663342150",
                "contactType": "customer service",
                "areaServed": ["150","PL","GB","US"],
                "availableLanguage": ["Polish","en"]
              },
              "sameAs": [
                "https://www.facebook.com/shovvpl/",
                "https://www.instagram.com/shovvpl/"
              ]
            }
          `}
        </script>
      </Helmet>
      {/* Navigation */}
      <Header />

      <section
        className="section preview-section websites-hero"
        style={
          supportWebp
            ? {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero.webp'
                })`,
              }
            : {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero.jpg'
                })`,
              }
        }
      >
        <div className="scroll-bottom go-to">
          <a href="#strony-internetowe">Scroll</a>
        </div>
        <Social />
      </section>

      {/* SEA IMAGES SECTION */}
      <section className="rectangles-section section light-grey pt-0">
        <div className="container ">
          <div className="row justify-content-center">
            <div
              id="strony-internetowe"
              className="col-12 col-lg-8 bg-white white-part"
            >
              <h1 className="px-3 mb-4 mx-lg-4">
                <span>STRONY INTERNETOWE</span>
                DLA BRANŻY PRAWNICZEJ
              </h1>
              <p className="px-3 mb-3">
                Prowadzisz kancelarię prawną? Jesteś adwokatem lub radcą
                prawnym? Warto umożliwić klientowi odnalezienie Twojej oferty za
                pomocą strony internetowej. W dzisiejszych czasach internet jest
                pierwszym miejscem poszukiwań usług i informacji. Posiadanie
                nowoczesnej responsywnej strony internetowej może zadecydować o
                tym, że klient na nią trafi i wybierze właśnie Ciebie.
              </p>
              <p className="px-3 mb-3">
                Strona internetowa kancelarii jest Twoją wizytówką, a często też
                miejscem pierwszego kontaktu klienta z prowadzoną przez Ciebie
                działalnością. Prócz aspektu wizualnego warto zadbać także o
                wrażenia użytkownika podczas odwiedzin Twojej strony
                internetowej.
              </p>
            </div>
          </div>
        </div>
        <div className="container featured-content ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="Strony internetowe radca prawny"
                />
              </picture>
            </div>
          </div>
          <div className="row justify-content-center featured-content__wrapper">
            <div className="col-12 col-lg-4 bg-dark featured-content__text">
              <h3 className="px-3 mb-4">
                <span>strona internetowa</span> kancelarii
              </h3>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Pierwsze wrażenie możemy zrobić tylko raz. Nie inaczej ma się
                sprawa w przypadku strony internetowej. Warto zadbać aby to było
                dobre wrażenie.
              </p>
              <p className="px-3 mb-3">
                Kluczowym aspektem jest responsywność strony internetowej,
                mianowicie aby prezentowała się ona poprawnie zarówno na
                komputerze jak i tablecie czy smartfonie.
              </p>
              <p className="px-3 mb-3">
                Performance strony internetowej jest również bardzo ważnym
                elementem nie tylko z punktu widzenia SEO, ale także wrażeń
                użytkownika. W końcu kto lubi czekać aż strona internetowa się
                załaduje? Badania pokazują, że 53% użytkowników opuszcza stronę
                internetową, jeżeli ta ładuje się dłużej niż 3 sekundy!
              </p>
              <p className="px-3 mb-3">
                Posiadanie wyróżniającego się designu pozwoli nam w oczach
                klienta na łatwe odróżnienie się od konkurencji.
              </p>
              <p className="px-3 mb-3">
                Istotna będzie także treść i architektura informacji
                zaprezentowana na stronie internetowej. Pozwól użytkownikowi
                zrealizować cel jego wizyty.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*END  SEA IMAGES SECTION */}

      {/* FORM SECTION */}
      <section className="brief-form__section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-5">
              <h3 className="px-3 mb-4">
                <span>Darmowa wycena</span> wypełnij formularz
              </h3>

              <p className="px-3">
                Zapoznamy się z prowadzonym przez Ciebie biznesem i poznamy
                Twoją konkurencję, a następnie się skontaktujemy, aby wspólnie
                opracować strategię i wybrać optymalne rozwiązanie dopasowane do
                Twoich potrzeb.
              </p>
              <p className="px-3">
                Odpowiadamy w przeciągu 24h na pytania złożone od poniedziałku
                do piątku w godzinach 9:00 - 17:00. Na pozostałe zapytania
                odpowiadamy kolejnego dnia roboczego.
              </p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5">
              <Brief />
            </div>
          </div>
        </div>
      </section>
      {/*END FORM SECTION */}

      <section className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">
                <span>Nasze</span> realizacje
              </h3>
            </div>
          </div>
          {/* End .row */}
          <OurWebsSection />
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>STRONY WWW</span> DLA ADWOKATÓW
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Strony internetowe dla adwokatów nie mogą mieć charakteru
                reklamowego. Pomimo tego strona internetowa kancelarii o
                charakterze informacyjnym w dalszym ciągu tworzy miejsce na
                unikalny design i jest doskonałym narzędziem budowania
                wizerunku. Pomagamy klientom wykorzystać tą przestrzeń w
                możliwie najskuteczniejszy sposób.
              </p>
              <p className="px-3 mb-3">
                Ponadto posiadanie strony internetowej w znaczący sposób wzbudza
                zaufanie wśród klientów.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>STRONY WWW </span> DLA RADCÓW PRAWNYCH
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Strona internetowa kancelarii radcowskiej podobnie jak w
                przypadku adwokatów nie może używać języka korzyści ani mieć
                charakteru reklamowego.
              </p>
              <p className="px-3 mb-3">
                Odwiedzając strony prawnicze często możemy odnieść wrażenie, że
                wszystkie wyglądają tak samo i niewiele je wyróżnia.
              </p>
              <p className="px-3 mb-3">
                Posiadanie wizerunku godnego zaufania przekłada się w realny
                sposób na kontakt ze strony klientów. Dobrze prowadzona strona
                internetowa jest miejscem odwiedzin setek ludzi każdego dnia.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>STRONY WWW </span>DLA PRAWNIKÓW
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Branża prawnicza to biznes jak każdy inny. Na rynku prawników w
                naszym kraju istnieje ogromna konkurencja. Warto zadbać aby się
                wyróżnić i dzięki temu pozyskać nowych klientów.
              </p>
              <p className="px-3 mb-3">
                Samo wyróżnienie może być niewystarczające. Klient zanim
                powierzy kancelarii swoje interesy musi ją kojarzyć z
                bezpieczeństwem, a przede wszystkim musi posiadać do niej
                zaufanie. Pośredni wpływ na te odczucia mamy właśnie poprzez
                stronę internetową, która często jest miejscem pierwszego
                kontaktu z kancelarią.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section content-row-center light-grey ">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div className="purchase-block-inner">
                <h3 className="pt-0">
                  <span>SKUTECZNA STRONA INTERNETOWA</span>
                  KANCELARII PRAWNEJ
                </h3>
                <p className="px-3">
                  Sztuką jest stworzenie strony internetowej, która będzie
                  prosta w obsłudze dla starszych użytkowników, a jednocześnie
                  atrakcyjna dla młodszych. Intuicyjna nawigacja i dobrze
                  przemyślana architektura informacji są wbrew pozorom
                  decydujące czy klient pozostanie na naszej stronie czy jednak
                  ja opuści na korzyść konkurencji.
                </p>
                <p className="px-3">
                  Nowoczesna strona internetowa powinna być wyposażona w
                  narzędzia śledzenia konwersji, aby sprawdzić czy prowadzone
                  przez nas działania w ogóle przynoszą zamierzony rezultat -
                  przekładają się na klientów.
                </p>
                <p className="px-3">
                  Posiadanie wiedzy na temat współczynnika konwersji jest
                  punktem wyjściowym do dalszej optymalizacji strony
                  internetowej, a w rezultacie poprawy jej skuteczności.
                </p>
              </div>
              {/* End .purchase-block-inner */}
            </div>
          </div>
        </div>
      </section>

      <section className="section our-technologies">
        <div className="container">
          <div className="row px-3">
            <div className="col-12">
              <h3>
                <span>technologie</span>
                <br /> w których pracujemy
              </h3>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/react.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/react.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/react.webp"
                    type="image/webp"
                    alt="strona internetowa dla radcy prawnego react js"
                  />
                </picture>
              </div>
              <h5>React js</h5>
            </div>
            <div className="col-6 col-md-3 offset-lg-1 col-lg-2 ">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/vue.webp"
                    type="image/webp"
                  />
                  <source srcSet="/img/technologies/vue.png" type="image/png" />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/vue.webp"
                    type="image/webp"
                    alt="strona www dla kancelarii adwokackiej vue js"
                  />
                </picture>
              </div>
              <h5>vue js</h5>
            </div>
            <div className="col-6 col-md-3 offset-lg-1 col-lg-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/wordpress.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/wordpress.png"
                    type="image/jpg"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/wordpress.webp"
                    type="image/webp"
                    alt="strona www wordpress"
                  />
                </picture>
              </div>
              <h5>wordpress</h5>
            </div>
            <div className="col-6 col-md-3 offset-lg-1 col-lg-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/html.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/html.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/html.webp"
                    type="image/webp"
                    alt="strona internetowa dla adwokata html"
                  />
                </picture>
              </div>
              <h5>html</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="rightSide-section">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-10 offset-lg-4 col-lg-8 rightSide-content">
              <h3 className="px-3">
                <span>dlaczego warto</span>
                <br /> współpracować z SHOVV
              </h3>
              <p className="px-3 mb-3">
                Od 2015 roku pomagamy Klientom w osiągnięciu jak najwyższych
                zysków ze sprzedaży usług online.
              </p>
              <p className="px-3 mb-3">
                Wieloletnia obecność na rynku umożliwiła nam wniknięcie w branże
                prawniczą i poznanie jej z perspektywy klienta oraz właściciela
                kancelarii.
              </p>
              <p className="px-3 mb-3">
                Mieliśmy przyjemność zrealizować strony internetowe dla wielu
                kancelarii adwokackich, radcowskich, notarialnych i komorniczych
                w miastach takich jak Warszawa, Poznań, Wrocław, Gdańsk,
                Katowice, Białystok i wielu innych.
              </p>
              <p className="px-3 mb-3">
                Gwarantujemy wysoką jakość wykonywanych usług, w biznesie
                stawiamy na partnerstwo. Zapraszamy do współpracy
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="px-3 px-md-0">
                <span>ILE KOSZTUJE </span>
                WYKONANIE STRONY INTERNETOWEJ?
              </h3>
              <p className="px-3 px-md-0">
                Koszt stworzenia profesjonalnej strony internetowej zależy od
                kilku czynników. Takimi czynnikami są na przykład wersje
                językowe, system CMS, skomplikowanie i poziom rozbudowania oraz
                dodatkowe integracje np. z systemem CMR.
              </p>
              <p className="px-3 px-md-0">
                Często wybierając najtańszą usługę na rynku nie otrzymasz żadnej
                gwarancji na realizacje ani wsparcie posprzedażowego, co może
                się wiązać z koniecznością zatrudnienia kolejnego podmiotu do
                rozwiązania Twojego problemu. Warto wybrać rzetelną firmę, która
                istnieje od wielu lat na rynku i posiada bogate doświadczenie.
              </p>
              <p className="px-3 px-md-0">
                Podsumowując koszt witryny internetowej to sprawa indywidualna,
                ale można przyjąć, że ceny zaczynają się od 1 500 zł.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section testimonial">
        <div className="container">
          <h3 className="text-center">
            <span>klienci</span> o nas
          </h3>
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}

      {/* Contact */}
      <section id="contact" className="section after-left-section ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <h3>
                  <span>Napisz </span>do nas
                </h3>
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default Websites;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { ReactComponent as LockIcon } from 'assets/icons/padlock.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

const Brief = () => {
  const [isVerify, setisVerify] = useState(false);
  const [showCaptchaWarning, setShowCaptchaWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onChange = (value) => {
    if (!value) {
      setShowCaptchaWarning(true);
      setisVerify(false);
    } else {
      setisVerify(true);
      setShowCaptchaWarning(false);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (isVerify) {
      try {
        const response = await Axios.post(
          'https://shovv.pl/brief-form.php',
          data
        );

        reset();
        setShowCaptchaWarning(false);
        setShowSuccess(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setShowCaptchaWarning(true);
    }
  };

  return (
    <>
      <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register('briefname', { required: true })}
              />
              <label className="form-label">Nazwa firmy / strona www</label>
              {errors.briefname && errors.briefname.type === 'required' && (
                <span className="invalid-feedback">To pole jest wymagane</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register(
                  'briefemail',
                  {
                    required: 'Email jest wymagany',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Upewnij się, że Twój email jest poprawny',
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.briefemail && (
                <span className="invalid-feedback">
                  {errors.briefemail.message}
                </span>
              )}
            </div>
          </div>
          {/* End .col-12 */}
          <div className="col-12">
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Numer telefonu"
                {...register('phone', { required: true })}
              />
              <label className="form-label">Numer telefonu</label>
              {errors.phone && (
                <span className="invalid-feedback">To pole jest wymagane</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          {/* End .col-12 */}
          <div className="col-12 mb-2">
            <label className="checkbox-label">
              <input
                {...register('privacypolicy', { required: true })}
                value={true}
                type="checkbox"
                className="mr-2"
              />
              Akceptuję{' '}
              <Link className="" to="/polityka-prywatnosci">
                politykę prywatności
              </Link>
            </label>
            {errors.privacypolicy && (
              <span className="mt-0 invalid-feedback">
                To pole jest wymagane
              </span>
            )}
          </div>
          <ReCAPTCHA
            // shovv.pl
            sitekey="6LeyOO4dAAAAAMX2RGZ_mH3O8bulXjN668esHIRv"
            onChange={onChange}
          />
          {showCaptchaWarning && showSuccess === false ? (
            <span className="invalid-feedback">
              Zaznacz, że nie jesteś robotem
            </span>
          ) : null}
          {showSuccess ? (
            <span className="success-msg">Twoja wiadomość została wysłana</span>
          ) : null}
          <div className="col-12">
            <div className="btn-bar">
              <button className="mt-3 px-btn px-btn-theme">Wyślij</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>

        <div className="d-flex mt-4 align-item">
          <LockIcon style={{ marginTop: '-3px' }} />
          <p
            className="mb-0"
            style={{ color: '#009A17', fontSize: '12px', marginLeft: '5px' }}
          >
            Twoje dane są bezpieczne
          </p>
        </div>
      </form>
    </>
  );
};

export default Brief;

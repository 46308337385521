import React from "react";


const WebCards = () => {


  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 my-3">
          <a href="https://korolczukradca.pl/" target="_blank" rel="nofollow noreferrer">
          <div className="blog-post" >
            <div className="blog-img">
                <picture>
                  <source srcSet="/img/ourwebs/radca-prawny-anna-irena-korolczuk.webp" type="image/webp" />
                  <source srcSet="/img/ourwebs/radca-prawny-anna-irena-korolczuk.jpg" type="image/jpg" />
                  <img className="img-fluid w-100" src="/img/ourwebs/radca-prawny-anna-irena-korolczuk.webp" type="image/webp" alt="Strona www Radca Prawny Anna Korolczuk" />
              </picture>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Radca Prawny Anna Korolczuk</h6>
              {/* <p>
                Integer vitae mi libero. Aliquam consequat dolor libero, nec
                varius elit tempusporttitor.
              </p> */}
              <div className="btn-bar">Zobacz stronę</div>
            </div>
            {/* End blog-info */}
          </div>
          </a>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
        <a href="https://aresztdomowy.com/" target="_blank" rel="nofollow noreferrer">
          <div className="blog-post">
            <div className="blog-img">
              <picture>
                  <source srcSet="/img/ourwebs/adw-ewa-solaniuk.webp" type="image/webp" />
                  <source srcSet="/img/ourwebs/adw-ewa-solaniuk.jpg" type="image/jpg" />
                  <img className="img-fluid w-100" src="/img/ourwebs/adw-ewa-solaniuk.webp" type="image/webp" alt="Strona internetowa Adwokat Ewa Solaniuk" />
              </picture>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Adwokat <br />Ewa Solaniuk</h6>
             
              <div className="btn-bar">Zobacz stronę</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
          </a>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <a href="https://www.kancelaria-restrukturyzacyjna.pl/" target="_blank" rel="nofollow noreferrer">
            <div className="blog-post" >
              <div className="blog-img">
                <picture>
                    <source srcSet="/img/ourwebs/kancelaria-restrukturyzacyjna.webp" type="image/webp" />
                    <source srcSet="/img/ourwebs/kancelaria-restrukturyzacyjna.jpg" type="image/jpg" />
                    <img className="img-fluid w-100" src="/img/ourwebs/kancelaria-restrukturyzacyjna.webp" type="image/webp" alt="Strona internetowa Kancelarii Restrukturyzacyjnej" />
                </picture>
              </div>
              {/* End blog-img */}

              <div className="blog-info">
                <h6>Kancelaria Restrukturyzacyjna</h6>
                {/* <p>
                  Estibulum eu ante sed dui porta porttitor. Nullam a placerat
                  velit. Suspendisse non ligula felis
                </p> */}
                <div className="btn-bar">Zobacz stronę</div>
              </div>
              {/* End blog-info */}
            </div>
          {/* End blog-post */}
          </a>
        </div>
        {/* End .col for blog-1 */}
        <div className="col-lg-4 col-md-6 my-3">
          <a href="https://prawo-karne-gospodarcze.pl/" target="_blank" rel="nofollow noreferrer">
          <div className="blog-post" >
            <div className="blog-img">
                <picture>
                  <source srcSet="/img/ourwebs/prawo-karne-gospodarcze.webp" type="image/webp" />
                  <source srcSet="/img/ourwebs/prawo-karne-gospodarcze.jpg" type="image/jpg" />
                  <img className="img-fluid w-100" src="/img/ourwebs/prawo-karne-gospodarcze.webp" type="image/webp" alt="Strona internetowa Kancelaria adwokacka Warszawa" />
              </picture>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Prawo Karne Gospodarcze</h6>
              {/* <p>
                Integer vitae mi libero. Aliquam consequat dolor libero, nec
                varius elit tempusporttitor.
              </p> */}
              <div className="btn-bar">Zobacz stronę</div>
            </div>
            {/* End blog-info */}
          </div>
          </a>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
        <a href="https://adwokaci.bialystok.pl/" target="_blank" rel="nofollow noreferrer">
          <div className="blog-post">
            <div className="blog-img">
              <picture>
                  <source srcSet="/img/ourwebs/adwokaci-bialystok.webp" type="image/webp" />
                  <source srcSet="/img/ourwebs/adwokaci-bialystok.jpg" type="image/jpg" />
                  <img className="img-fluid w-100" src="/img/ourwebs/adwokaci-bialystok.webp" type="image/webp" alt="Strona www Kancelarii Prawnej Adwokaci Bialystok" />
              </picture>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>M.Głębicki, J.Kołodko, M. Parzych &amp; T.Śliwiński Sp.J.</h6>
             
              <div className="btn-bar">Zobacz stronę</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
          </a>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <a href="https://www.elizakorsak.pl/" target="_blank" rel="nofollow noreferrer">
            <div className="blog-post" >
              <div className="blog-img">
                <picture>
                    <source srcSet="/img/ourwebs/eliza-korsak.webp" type="image/webp" />
                    <source srcSet="/img/ourwebs/eliza-korsak.jpg" type="image/jpg" />
                    <img className="img-fluid w-100" src="/img/ourwebs/eliza-korsak.webp" type="image/webp" alt="Strona Inernetowa Kancelarii Adwokackej Adwokat Eliza Korsak" />
                </picture>
              </div>
              {/* End blog-img */}

              <div className="blog-info">
                <h6>Adwokat Eliza Korsak</h6>
                {/* <p>
                  Estibulum eu ante sed dui porta porttitor. Nullam a placerat
                  velit. Suspendisse non ligula felis
                </p> */}
                <div className="btn-bar">Zobacz stronę</div>
              </div>
              {/* End blog-info */}
            </div>
          {/* End blog-post */}
          </a>
        </div>
        {/* End .col for blog-1 */}


        
      </div>
      {/* End .row */}
    </>
  );
};

export default WebCards;

import React, { useState, createContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(window.navigator.language);

  // console.log('CONTEXT', language);

  // useEffect(() => {
  //   if (browserLanguage.includes('en')) {
  //     console.log('wyszukiwarka po angielsku');
  //     setLanguage('en');
  //   } else {
  //     setLanguage('pl');
  //   }
  // }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import LanguageContext from '../providers/langContext';

const LanguageMenu = () => {
  const { setLanguage } = useContext(LanguageContext);
  const history = useHistory();

  const handlePL = () => {
    setLanguage('pl');
    history.push(`/pl/strony-internetowe`);
  };

  const handleEng = () => {
    setLanguage('en');
    history.push(`/en/vue`);
  };

  return (
    <>
      {history.location.pathname.includes('/pl') ? (
        <li>
          <button className="changeLanguageBtn" onClick={handleEng}>
            EN
          </button>
        </li>
      ) : (
        <li>
          <button className="changeLanguageBtn" onClick={handlePL}>
            PL
          </button>
        </li>
      )}
    </>
  );
};

export default LanguageMenu;

import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Benefits from 'components/benefits/Benefits';
import Header from '../components/header/Header-en';
import Contact from '../components/contact/ContactEN';
import Footer from '../components/footer/Footer';
import Testimonial from '../components/testimonial/Testimonial';
import imgJPG from 'assets/images/sea.jpg';
import imgWebp from 'assets/images/sea.webp';
import Brief from '../components/brief-form/BriefEN';
import { checkWebPSupport } from 'supports-webp-sync';
import Social from 'components/Social';
import AddressData from 'components/contact/AddressData';
import { useLocation } from 'react-router-dom';
import LanguageContext from 'providers/langContext';

const PhalconPage = () => {
  const supportWebp = checkWebPSupport();
  const location = useLocation();
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (location.pathname.includes('/en/')) {
      setLanguage('en');
    }
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Phalcon PHP Development Company | SHOVV</title>
        <meta
          name="description"
          content="We offer Phalcon PHP development services for many solutions across various industries. Phalcon developers to hire."
        />
        <link rel="canonical" href="https://shovv.pl/pl/phalcon" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Phalcon PHP Development Company | SHOVV"
        />
        <meta
          property="og:description"
          content="We offer Phalcon PHP development services for many solutions across various industries. Phalcon developers to hire."
        />
        <meta property="og:url" content="https://shovv.pl/pl/phalcon" />
        <meta
          property="og:site_name"
          content="Phalcon PHP Development Company | SHOVV"
        />
        <meta property="og:image" content="https://shovv.pl/img/hero-en.jpg" />
        <meta
          property="og:image:secure_url"
          content="https://shovv.pl/img/hero-en.jpg"
        />
        <meta property="og:image:width" content="1622px" />
        <meta property="og:image:height" content="1081px" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="We offer Phalcon PHP development services for many solutions across various industries. Phalcon developers to hire."
        />
        <meta
          name="twitter:title"
          content="Phalcon PHP Development Company | SHOVV"
        />
        <meta name="twitter:image" content="https://shovv.pl/img/hero-en.jpg" />
      </Helmet>
      <Header />

      <section
        className="section preview-section"
        style={
          supportWebp
            ? {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero-en.webp'
                })`,
              }
            : {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/hero-en.jpg'
                })`,
              }
        }
      >
        <div className="scroll-bottom go-to">
          <a href="#about">Scroll</a>
        </div>
        <Social />
      </section>

      {/* SEA IMAGES SECTION */}
      <section className="rectangles-section section light-grey pt-0">
        <div className="container ">
          <div className="row justify-content-center">
            <div id="about" className="col-12 col-lg-8 bg-white white-part">
              <h1 className="px-3 mb-4 mx-lg-4">
                <span>PHALCON FRAMEWORK</span>
                DEVELOPMENT COMPANY
              </h1>
              <p className="px-3 mb-3">
                Do you need to build a high performing API or website? Phalcon
                PHP Framework might be a right choice for that solution, cause
                of it's perfect balance between size and performance. Phalcon
                PHP is one of the fastest MVC frameworks for PHP available. It's
                written in C and delivered as compiled PHP extension, so it
                doesn't need to be interpreter at every HTTP request.
              </p>
              <p className="px-3 mb-3">
                We are small team of experienced developers based in Poland.
                There is no middleman, meaning communication is clear and our
                reaction is quick. Over the years we helped to build strong
                online presence for many businesses across various industries.
              </p>
            </div>
          </div>
        </div>
        <div className="container featured-content ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="High Performance API in Phalcon"
                />
              </picture>
            </div>
          </div>
          <div className="row justify-content-center featured-content__wrapper">
            <div className="col-12 col-lg-4 bg-dark featured-content__text">
              <h3 className="px-3 mb-4">
                <span>Why do we</span> use PHALCON PHP?
              </h3>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Phalcon PHP comes off as truly unique. It's a free, open-source
                solution with perfect balance between size and performance.
              </p>
              <p className="px-3 mb-3">
                It's lightning fast since it's written in C and precompiled,
                meaning the code is not interpreted at runtime like regular PHP
                framework.
              </p>
              <p className="px-3 mb-3">
                Currently it is one of the leading MVC frameworks for PHP to
                build high-performance robust and well secured web applications.
              </p>
              <p className="px-3 mb-3">
                It's approach to typical solutions like services and dependency
                injection is highly different from those commonly delivered by
                most of MVC frameworks for PHP.
              </p>
              <p className="px-3 mb-3">
                Phalcon PHP provides faster development proces that helps to
                build web applications that are secure, performant and scalable,
                while less server resources consumption of it's competition.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*END  SEA IMAGES SECTION */}

      {/* FORM SECTION */}
      <section className="brief-form__section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-5">
              <h3 className="px-3 mb-4">
                <span>Complete the form</span> to get free quote
              </h3>
              <p className="px-3">
                We will get to know your business and get to know your
                competitors, and then we will contact you to jointly develop a
                strategy and choose the optimal solution tailored to your needs.
              </p>
              <p className="px-3">
                We respond within 24 hours to questions submitted from Monday to
                Friday, from 9:00 am to 5:00 pm. We respond to other inquiries
                on the next business day.
              </p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5">
              <Brief />
            </div>
          </div>
        </div>
      </section>
      {/*END FORM SECTION */}

      <section className="section light-grey">
        <Benefits />
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>rich </span> functionality
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Phalcon PHP is full-stack solution meaning it comes with
                comprehensive and standardized set of ready-made tools and
                components.
              </p>
              <p className="px-3 mb-3">
                DevTools are collection of useful scripts to speed up
                development process. It allows to generate skeleton code of
                application with a simple command.
              </p>
              <p className="px-3 mb-3">
                Phalcon PHP is also famous for implementing object relational
                mapping in C as a first.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>COST</span> EFFECTIVE
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                File reads and file stats are expensive operations of most PHP
                frameworks out there. Phalcon PHP eliminates need to keep any
                information off-memory.
              </p>
              <p className="px-3 mb-3">
                Phalcon's efficient memory utilization is one of the main
                advantages that enhance efficiency and optimize performance. In
                simple words you can load it once, then it runs in background
                continuously.
              </p>
              <p className="px-3 mb-3">
                Phalcon handles more HTTP requests than other frameworks.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>SPEED OF C</span>
                <br /> With FLEXIBILITY OF PHP
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Phalcon PHP is greatly differ from other frameworks. It's all
                about low-level architecture.
              </p>
              <p className="px-3 mb-3">
                Since you not install it in your project directory. Instead
                Phalcon's core is written in C and Zephir programing language,
                parsed to C, compiled and supplied as C-extension for PHP.
              </p>
              <p className="px-3 mb-3">
                Built-in functions delivered as ready-to-use PHP classes makes
                developing process easy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section content-row-center light-grey ">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div className="purchase-block-inner">
                <h3 className="pt-0">
                  <span>PHALCON PHP</span> FOR HIGH-LOAD RESTFUL API
                </h3>
                <p className="px-3">
                  When it comes to creating high-load API based on MVC framework
                  usually a lot of caching are involved.
                </p>
                <p className="px-3">
                  Phalcon PHP doesn't require core files, meaning in comparison
                  to other PHP frameworks memory usage is low. Also it uses less
                  files per request, that means faster processing.
                </p>
                <p className="px-3">
                  Phalcon PHP supports both - full-stack and micro framework.
                  When we develop micro application we can still use most of
                  Phalcon's features, while remaining faster than full-stack
                  option.
                </p>
                <p className="px-3">
                  Benchmarks shown us that Phalcon PHP is able to complete over
                  1400 requests per second.
                </p>
              </div>
              {/* End .purchase-block-inner */}
            </div>
          </div>
        </div>
      </section>

      <section className="section our-technologies">
        <div className="container">
          <div className="row px-3">
            <div className="col-12">
              <h3>
                <span>TECHNOLOGIES</span>
                <br />
                we work with
              </h3>
            </div>
            <div className="col-6 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/react.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/react.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/react.webp"
                    type="image/webp"
                    alt="web application react js"
                  />
                </picture>
              </div>
              <h5>React JS</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/vue.webp"
                    type="image/webp"
                  />
                  <source srcSet="/img/technologies/vue.png" type="image/png" />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/vue.webp"
                    type="image/webp"
                    alt="single page application vue js"
                  />
                </picture>
              </div>
              <h5>vue js</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/wordpress.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/wordpress.png"
                    type="image/jpg"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/wordpress.webp"
                    type="image/webp"
                    alt="websites wordpress"
                  />
                </picture>
              </div>
              <h5>wordpress</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <img
                  className="img-fluid w-100"
                  src="/img/technologies/phalcon.svg"
                  type="image/webp"
                  alt="high performance Phalcon websites"
                />
              </div>
              <h5>Phalcon</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="rightSide-section">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-10 offset-lg-4 col-lg-8 rightSide-content">
              <h3 className="px-3">
                <span>WHY CHOOSE</span>
                <br />
                SHOVV?
              </h3>
              <p className="px-3 mb-3">
                SHOVV is software development company that offers narrow range
                of specialized services such as React JS, Vue JS and Phalcon
                PHP. Since 2015 we help our clients to develop web apps across
                various industries.
              </p>
              <p className="px-3 mb-3">
                We provide our customers with affordable pricing options which
                will suit your project budget.
              </p>
              <p className="px-3 mb-3">
                Our developers can join your project at any stage of development
                to work hand-in-hand with your existing team.
              </p>
              <p className="px-3 mb-3">
                In SHOVV we base our development process on agile methodologies
                like scrum to keep you up to date. Constant self-improvement and
                self-organization is a daily thing.
              </p>
              <p className="px-3 mb-3">
                You are free to reach SHOVV developers anytime.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="px-3 px-md-0">
                <span>Hire us for your next</span>
                <br />
                PHALCON PHP PROJECT
              </h3>
              <p className="px-3 px-md-0">
                First step in developing proces is understanding your project,
                it's requirements and goals.
              </p>
              <p className="px-3 px-md-0 mb-3">
                With this knowledge, we then draw up a rough blueprint of
                solution for your project.
              </p>
              <p className="px-3 px-md-0 mb-3">
                In the next step we can move to the development. This is where
                we release iterations of your project to gain feedback from
                client. It's a good place to incorporate your suggestions.
              </p>
              <p className="px-3 px-md-0 mb-3">
                We work with clients all over the world, from startups to
                enterprises.
              </p>
              <p className="px-3 px-md-0 mb-3">
                Costs of development relay on many factors. We apply individual
                approach to each client. Above you can fill form and we will
                reach you about estimation of your project.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section testimonial">
        <div className="container">
          <div className="row px-3 px-md-0">
            <h3>
              <span>Our clients</span> confirm we deliver
            </h3>
            <Testimonial />
          </div>
        </div>
      </div>
      {/* End testimonial */}

      {/* Contact */}
      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <h3>
                  <span>Contact </span>us
                </h3>
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default PhalconPage;

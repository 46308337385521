import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
  const [isVerify, setisVerify] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onChange = (value) => {
  
    if (!value) {
      setShowWarning(true)
      setisVerify(false);
    } else {
      setisVerify(true);
      setShowWarning(false) 
    }
  }
  
  const onSubmit = async (data, e) => {
    // e.target.reset();
    e.preventDefault();
    
    
    if(isVerify) {
      try {
        const response = await Axios.post('https://shovv.pl/contact-form.php', data)
        reset() 
        setShowWarning(false)
        setShowSuccess(true)
      } catch(err) {
        console.log(err)
      }
    } else {
      setShowWarning(true)
    }
  
    
  };

  return (
    <>
      <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              <label className="form-label">Imię</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Imię jest wymagane</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register(
                  "email",
                  {
                    required: "Email jest wymagany",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Upewnij się, że Twój email jest poprawny",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}


          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register("comment", { required: true })}
              ></textarea>
              <label className="form-label">Treść wiadomości</label>
              {errors.comment && (
                <span className="invalid-feedback">Treść wiadomości jest wymagana</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}
          <ReCAPTCHA
            // shovv.pl
            sitekey="6LeyOO4dAAAAAMX2RGZ_mH3O8bulXjN668esHIRv"
            // warszawska-grupa-prawna.pl
            // sitekey="6LcZmfAdAAAAAKzjXfjRK6BELRu93YdLYg2XjYho"
            onChange={onChange}
          />
          {showWarning && showSuccess === false ? <span className="invalid-feedback">Zaznacz, że nie jesteś robotem</span> : null}
          {showSuccess ? <span className="success-msg">Twoja wiadomość została wysłana</span> : null}
          <div className="col-12">
            <div className="btn-bar">
              {/* disabled={!isVerify} */}
              {/*  className="mt-3 px-btn px-btn-theme" */}
              <button className="mt-3 px-btn px-btn-theme">Wyślij</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;

import React, { useContext } from 'react';
import imgJPG from 'assets/images/ceo-founder.jpg';
import imgWebp from 'assets/images/ceo-founder.webp';
import phone from 'assets/images/phone-number.svg';
import mail from 'assets/images/mail.svg';
import LanguageContext from 'providers/langContext';

const AddressData = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="px-3 px-md-0 col-lg-4 ml-auto my-3">
      <div className="contact-info">
        <div className="sm-title">
          <div className="d-flex align-items-center">
            <div className="img-wrapper-founder">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="CEO Marek Stankiewicz"
                />
              </picture>
            </div>
            <div className="img-description-wrapper">
              <h5>Marek Stankiewicz</h5>
              <span className="media-body">CEO &amp; Founder</span>
            </div>
          </div>
        </div>
        {/* End .title */}

        <div className="media">
          <div className="icon">
            <i className="ti-map"></i>
          </div>
          <span className="media-body">
            Warszawska 21, lok. 115, <br />
            15-157 Białystok, {language === 'pl' ? 'Polska' : 'Poland'}
          </span>
        </div>
        {/* End media */}

        <div className="media d-flex align-items-center">
          <div className="icon">
            <i className="ti-email"></i>
          </div>

          <span className="media-body">
            <img src={mail} alt="email SHOVV" />
          </span>
        </div>
        {/* End media */}

        <div className="media d-flex align-items-center">
          <div className="icon">
            <i className="ti-mobile"></i>
          </div>
          <span className="media-body">
            <img src={phone} alt="phone SHOVV " />
          </span>
        </div>
        {/* End media */}
      </div>
    </div>
  );
};

export default AddressData;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      firstLetter: 'M',
      opinions: '3 opinions',
      date: '8 July',
      imageName: "team-1",
      desc: ` Comprehensive service, professionally, on time, project thoughtfully made, I recommend it!`,
      reviewerName: "Michał Wasiluk",
      rating: "5.0",
    },
    {
      firstLetter: 'K',
      opinions: '1 opinion',
      date: '17 December',
      imageName: "team-2",
      desc: `Fast, cheap and reliable - it's hard to find companies in the industry that combine these 3 features. Fortunately, SHOVV is overwhelmed by this heavy combo, so I choose them!`,
      reviewerName: "Karol Kiryk",
      rating: "5",
    },
    {
      firstLetter: 'J',
      opinions: '2 opinions',
      date: '22 December',
      imageName: "team-4",
      desc: `SHOVV is characterized by a creative approach to the project, extensive knowledge in the field of modern marketing and advertising. Very good contact, patience, reliability in every detail, building a common vision. I am very pleased with the cooperation. I recommend!`,
      reviewerName: "Janusz Tomzik",
      rating: "5.0",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            <div className="avatar">
              {val.firstLetter}
              {/* <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img> */}
            </div>
            {/* End avatar */}
            <div className="testmonial-text media-body">
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                <p>{val.opinions}</p>
                <div className="testimonial-stars-row">
                  <div className="stars">
                      <div className="star"></div>
                      <div className="star"></div>
                      <div className="star"></div>
                      <div className="star"></div>
                      <div className="star"></div>
                     
                  </div>
                  <div className="date">
                    {val.date}
                  </div>
                  <div className="new-opinion">NEW</div>
                </div>
        
              </div>
              <p className="desc">{val.desc}</p>
            </div>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}

import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header/Header';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import Brief from '../components/brief-form/Brief';
import OurWebsSection from 'components/cards/WebCards';
import Testimonial from 'components/testimonial/TestimonialPL';
import { checkWebPSupport } from 'supports-webp-sync';
import imgJPG from 'assets/images/seo-content-img.jpg';
import imgWebp from 'assets/images/seo-content-img.webp';
import Social from 'components/Social';
import AddressData from 'components/contact/AddressData';
import LanguageContext from 'providers/langContext';
import { useLocation } from 'react-router-dom';
const SeoPage = () => {
  const supportWebp = checkWebPSupport();
  const { setLanguage } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/pl/')) {
      setLanguage('pl');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>SEO i pozycjonowanie dla Adwokatów i Prawników 2022</title>
        <meta
          name="description"
          content="Lokalne SEO dla adwokatów i branży prawniczej. Pozycjonowanie zgodne z etyką adwokacką. Popraw widoczność Twojej kancelarii w internecie."
        />
        <link rel="canonical" href="https://shovv.pl/pl/pozycjonowanie" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="SEO i pozycjonowanie dla Adwokatów i Prawników 2022"
        />
        <meta
          property="og:description"
          content="Lokalne SEO dla adwokatów i branży prawniczej. Pozycjonowanie zgodne z etyką adwokacką. Popraw widoczność Twojej kancelarii w internecie."
        />
        <meta property="og:url" content="https://shovv.pl/pl/pozycjonowanie" />
        <meta
          property="og:site_name"
          content="SEO i pozycjonowanie dla Adwokatów i Prawników 2022"
        />
        <meta property="og:image" content="https://shovv.pl/img/heroseo.jpg" />
        <meta
          property="og:image:secure_url"
          content="https://shovv.pl/img/heroseo.jpg"
        />
        <meta property="og:image:width" content="1622px" />
        <meta property="og:image:height" content="1081px" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Lokalne SEO dla adwokatów i branży prawniczej. Pozycjonowanie zgodne z etyką adwokacką. Popraw widoczność Twojej kancelarii w internecie."
        />
        <meta
          name="twitter:title"
          content="SEO i pozycjonowanie dla Adwokatów i Prawników 2022"
        />
        <meta name="twitter:image" content="https://shovv.pl/img/heroseo.jpg" />
      </Helmet>
      <Header />
      <section
        className="hero-seo-page section preview-section"
        style={
          supportWebp
            ? {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/heroseo.webp'
                })`,
              }
            : {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/heroseo.jpg'
                })`,
              }
        }
      >
        <div className="scroll-bottom go-to">
          <a href="#seo">Scroll</a>
        </div>
        <Social />
      </section>

      {/* SEA IMAGES SECTION */}
      <section className="rectangles-section section light-grey pt-0">
        <div className="container ">
          <div className="row justify-content-center">
            <div id="seo" className="col-12 col-lg-8 bg-white white-part">
              <h1 className="px-3 mb-4 mx-lg-4">
                <span>Pozycjonowanie dla </span> kancelarii adwokackiej
              </h1>
              <p className="px-3 mb-3">
                Organiczne pozycjonowanie witryn internetowych prawników jest
                tak na prawdę jedną z niewielu opcji na dotarcie do klienta. W
                dzisiejszych czasach zazwyczaj pierwszym miejscem pozyskania
                informacji dotyczących niemalże wszystkich dziedzin życia jest
                wyszukiwarka Google.
              </p>
              <p className="px-3 mb-3">
                Użytkownicy wyszukiwarek internetowych chcą również odnaleźć
                adwokatów i radców prawnych. I tutaj sprawa jest nieco
                utrudniona, gdyż zbiór zasad etyki adwokackiej zakazuje
                reklamowania kancelarii prawnych. Zatem pozostaje nam tylko
                odpowiednia strategia SEO, na którą składa się przygotowanie
                contentu, analiza fraz oraz link building.
              </p>
            </div>
          </div>
        </div>
        <div className="container featured-content ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="Pozycjonowanie dla kancelarii adwokackiej"
                />
              </picture>
            </div>
          </div>
          <div className="row justify-content-center featured-content__wrapper">
            <div className="col-12 col-lg-4 bg-dark featured-content__text">
              <h3 className="px-3 mb-4">
                <span>seo dla</span> prawników
              </h3>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Jesteś radcą prawnym, adwokatem bądź też prawnikiem i chciałbyś
                pozyskiwać więcej klientów?
              </p>
              <p className="px-3 mb-3">
                Strona internetowa Twojej kancelarii znajduje się w popularnych
                serwisach promujących firmy i usługi lokalne, ale nie przekłada
                się to na kontakt ze strony klientów?
              </p>
              <p className="px-3 mb-3">
                Strona internetowa kancelarii nie jest widoczna w wynikach
                wyszukiwań lub nie osiąga satysfakcjonującej pozycji na frazy
                związane z prowadzoną przez Ciebie działalnością prawną?
              </p>
              <p className="px-3 mb-3">
                Zastanawiasz się w jaki sposób ją pozycjonować aby to zmienić?
              </p>
              <p className="px-3 mb-3">
                Naszą specjalizacją jest szeroko rozumiania optymalizacja SEO
                stron prawniczych, która umożliwi potencjalnym klientom
                odnalezienie Twojej oferty usług prawnych.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*END  SEA IMAGES SECTION */}

      {/* FORM SECTION */}
      <section className="brief-form__section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-5">
              <h3 className="px-3 mb-4">
                <span>Darmowa wycena</span> wypełnij formularz
              </h3>

              <p className="px-3">
                Zapoznamy się z prowadzonym przez Ciebie biznesem i poznamy
                Twoją konkurencję, a następnie się skontaktujemy, aby wspólnie
                opracować strategię i wybrać optymalne rozwiązanie dopasowane do
                Twoich potrzeb.
              </p>
              <p className="px-3">
                Odpowiadamy w przeciągu 24h na pytania złożone od poniedziałku
                do piątku w godzinach 9:00 - 17:00. Na pozostałe zapytania
                odpowiadamy kolejnego dnia roboczego.
              </p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5">
              <Brief />
            </div>
          </div>
        </div>
      </section>
      {/*END FORM SECTION */}

      {/* NASZE REALIZACJE */}
      <section className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Nasze realizacje</h3>
            </div>
          </div>
          {/* End .row */}
          <OurWebsSection />
        </div>
      </section>
      {/* END NASZE REALIZACJE */}

      <section className="section">
        <div className="container">
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>POZYCJONOWANIE</span> STRONY KANCELARII
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Jeżeli posiadasz już stronę internetową Twojej kancelarii
                prawnej warto rozpocząć pozycjonowanie od wykonania audytu SEO.
              </p>
              <p className="px-3 mb-3">
                Wybór odpowiedniej strategii i zaplanowanie przyszłych działań
                jest uzależniony od obszaru prowadzonej działalności oraz
                dziedziny prawa którą się zajmujesz.
              </p>
              <p className="px-3 mb-3">
                Nieodłącznym elementem pozycjonowania strony internetowej jest
                umieszczenie na niej jakościowego contentu.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>POZYCJONOWANIE</span> RADCÓW PRAWNYCH
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Zarówno radca prawny jak i adwokat może pozycjonować swoją
                stronę, ważne jest jednak aby nie używać języka korzyści, gdyż
                najczęściej jest on związany z reklamą.
              </p>
              <p className="px-3 mb-3">
                Samo podnoszenie pozycji w Google i innych wyszukiwarkach nie
                jest promowaniem usługi osobom niezainteresowanym, zatem nie
                łamie to zasad etyki zawodowej.
              </p>
              <p className="px-3 mb-3">
                Tworząc content na stronę radcy prawnego warto skoncentrować się
                na dostarczeniu artykułów o charakterze informacyjnym oraz
                eksperckim.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>POZYCJONOWANIE</span> LOKALNE
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Lokalne SEO to proces optymalizacji strony pod kątem słów
                kluczowych zawierających odniesienie do prowadzonej działalności
                oraz danego obszaru np. nazwy miejscowości, województwa.
              </p>
              <p className="px-3 mb-3">
                Frazy lokalne mogą z dużym prawdopodobieństwem sprowadzić na
                stronę kancelarii przyszłych klientów.
              </p>
              <p className="px-3 mb-3">
                Długoogonowe słowa kluczowe (tzw. long tail) takie jak np.
                "adwokat tymczasowe aresztowanie warszawa" mają mniejszą
                konkurencję niż frazy bardziej ogólne takie jak "adwokat
                warszawa" dzięki czemu możemy spodziewać się wyników szybciej.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section content-row-center gray-bg">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div className="purchase-block-inner">
                {/* <Fade bottom> */}
                <h3 className="pt-0">
                  <span>SEO, A ETYKA ADWOKACKA</span>
                  wybór odpowiedniej strategii
                </h3>
                <p className="px-3">
                  Czy radcy prawni i adwokaci mogą się reklamować i
                  pozycjonować? Zgodnie z Ustawą z dnia 26 maja 1982 r. Prawo o
                  adwokaturze, a także ze Zbiorem Zasad Etyki Adwokackiej i
                  Godności Zawodu, który uchwalony został w 1998 r. przez
                  Naczelną Radę Adwokacką zakazane jest reklamowanie kancelarii
                  prawnej.
                </p>
                <p className="px-3">
                  Czy pozycjonowanie w wyszukiwarkach internetowych to reklama?
                  Jednoznaczne informacje dotyczące pozycjonowania nie znalazły
                  się w Zbiorze Zasad Etyki Adwokackiej, ani też Krajowej Izby
                  Radców Prawnych. Należy więc przyjąć, że użytkownik wpisujący
                  frazę "adwokat wrocław" jest zainteresowany ofertą kancelarii
                  adwokackiej. Zatem nie jest to promowanie usługi dla osób
                  niezainteresowanych § 23b, pkt. 1 Kodeks Etyki Adwokackiej.
                </p>
                {/* </Fade> */}
              </div>
              {/* End .purchase-block-inner */}
            </div>
          </div>
        </div>
      </section>

      <section className="section our-technologies">
        <div className="container">
          <div className="row px-3">
            <div className="col-12">
              <h3>
                <span>technologie</span>
                <br /> w których pracujemy
              </h3>
            </div>
            <div className="col-6 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/react.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/react.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/react.webp"
                    type="image/webp"
                    alt="strona internetowa dla radcy prawnego react js"
                  />
                </picture>
              </div>
              <h5>React js</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/vue.webp"
                    type="image/webp"
                  />
                  <source srcSet="/img/technologies/vue.png" type="image/png" />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/vue.webp"
                    type="image/webp"
                    alt="strona www dla kancelarii adwokackiej vue js"
                  />
                </picture>
              </div>
              <h5>vue js</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/wordpress.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/wordpress.png"
                    type="image/jpg"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/wordpress.webp"
                    type="image/webp"
                    alt="strona www wordpress"
                  />
                </picture>
              </div>
              <h5>wordpress</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2">
              <div className="box">
                <picture>
                  <source
                    srcSet="/img/technologies/html.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/technologies/html.png"
                    type="image/png"
                  />
                  <img
                    className="img-fluid w-100"
                    src="/img/technologies/html.webp"
                    type="image/webp"
                    alt="strona internetowa dla adwokata html"
                  />
                </picture>
              </div>
              <h5>html</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="rightSide-section">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-10 offset-lg-4 col-lg-8 rightSide-content">
              <h3 className="px-3">
                <span>dlaczego warto</span>
                <br /> współpracować z SHOVV
              </h3>
              <p className="px-3 mb-3">
                Od 2015 roku pomagamy Klientom w osiągnięciu jak najwyższych
                zysków ze sprzedaży usług online.
              </p>
              <p className="px-3 mb-3">
                Wieloletnia obecność na rynku umożliwiła nam zdobycie
                niezbędnego doświadczenia oraz zbudowania odpowiedniej
                infrastruktury SEO, dzięki której jesteśmy w stanie świadczyć
                usługi na najwyższym poziomie dla branży prawniczej.
              </p>
              <p className="px-3 mb-3">
                Gwarantujemy wysoką jakość wykonywanych usług, w biznesie
                stawiamy na partnerstwo. Aby uniknąć konfliktu interesów na
                wybrane obszary i dziedziny prawa oferujemy klientom wyłączność.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="px-3 px-md-0">
                <span>jak szybko mogę</span>
                <br />
                spodziewać się wyników?
              </h3>
              <p className="px-3 px-md-0">
                Na efekty trzeba czekać. Jeżeli ktoś Ci obiecuje, że w krótkim
                czasie można przewyższyć najlepszych z Twojej branży, którzy na
                swoją pozycję pracowali od lat powinno to co najmniej wzbudzić
                Twoją uwagę.
              </p>
              <p className="px-3 px-md-0">
                SEO to złożony i czasochłonny proces, wymagający zebrania danych
                i ich analizy. Największą trudnością będzie stworzenie zaplecza
                jakościowych backlinków, ale tak na prawdę każdym element jest
                istotny.
              </p>
              <p className="px-3 px-md-0">
                Widoczne wyniki są zależne przede wszystkim od obszaru i
                dziedziny prawa, którą się zajmujesz. Zazwyczaj jest to od 3 do
                6 miesięcy.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <AfterRight /> */}

      <div className="section testimonial">
        <div className="container">
          <h3 className="text-center">
            <span>Klienci</span> o nas
          </h3>
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}

      {/* Contact */}
      <section id="contact" className="section after-left-section ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <h3>
                  <span>Napisz </span>do nas
                </h3>
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
};

export default SeoPage;

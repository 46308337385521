import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [isVerify, setisVerify] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onChange = (value) => {
    if (!value) {
      setShowWarning(true);
      setisVerify(false);
    } else {
      setisVerify(true);
      setShowWarning(false);
    }
  };

  const onSubmit = async (data, e) => {
    // e.target.reset();
    e.preventDefault();

    if (isVerify) {
      try {
        const response = await Axios.post(
          'https://shovv.pl/contact-form.php',
          data
        );

        reset();
        setShowWarning(false);
        setShowSuccess(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setShowWarning(true);
    }
  };

  return (
    <>
      <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register('name', { required: true })}
              />
              <label className="form-label">Name</label>
              {errors.name && errors.name.type === 'required' && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register(
                  'email',
                  {
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Make sure your email address is correct',
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register('comment', { required: true })}
              ></textarea>
              <label className="form-label">Your message</label>
              {errors.comment && (
                <span className="invalid-feedback">Message is required</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}
          <ReCAPTCHA
            // shovv.pl
            sitekey="6LeyOO4dAAAAAMX2RGZ_mH3O8bulXjN668esHIRv"
            onChange={onChange}
          />
          {showWarning && showSuccess === false ? (
            <span className="invalid-feedback">I'm not a robot</span>
          ) : null}
          {showSuccess ? (
            <span className="success-msg">Your message has been sent</span>
          ) : null}
          <div className="col-12">
            <div className="btn-bar">
              {/* disabled={!isVerify} */}
              {/*  className="mt-3 px-btn px-btn-theme" */}
              <button className="mt-3 px-btn px-btn-theme">Send</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;

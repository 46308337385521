import React, { useEffect, useContext } from 'react';
import Header from '../components/header/Header';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import { checkWebPSupport } from 'supports-webp-sync';
import imgJPG from 'assets/images/brand-content-img.jpg';
import imgWebp from 'assets/images/brand-content-img.webp';
import Brief from '../components/brief-form/Brief';
import Testimonial from '../components/testimonial/TestimonialPL';
import Social from 'components/Social';
import AddressData from 'components/contact/AddressData';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import LanguageContext from 'providers/langContext';

const CreateBrand = () => {
  const supportWebp = checkWebPSupport();
  const location = useLocation();
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (location.pathname.includes('/pl/')) {
      setLanguage('pl');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Identyfikacja wizualna kancelarii adwokackiej 2022</title>
        <meta
          name="description"
          content="Usługi projektowania identyfikacji wizualnej kancelarii adwokackich i prawnych. Projekty logo dla adwokata."
        />
        <link
          rel="canonical"
          href="https://shovv.pl/pl/identyfikacja-wizualna"
        />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Identyfikacja wizualna kancelarii adwokackiej 2022"
        />
        <meta
          property="og:description"
          content="Usługi projektowania identyfikacji wizualnej kancelarii adwokackich i prawnych. Projekty logo dla adwokata."
        />
        <meta
          property="og:url"
          content="https://shovv.pl/pl/identyfikacja-wizualna"
        />
        <meta
          property="og:site_name"
          content="Identyfikacja wizualna kancelarii adwokackiej 2022"
        />
        <meta
          property="og:image"
          content="https://shovv.pl/img/herobrand.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://shovv.pl/img/herobrand.jpg"
        />
        <meta property="og:image:width" content="1920px" />
        <meta property="og:image:height" content="1280px" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Usługi projektowania identyfikacji wizualnej kancelarii adwokackich i prawnych. Projekty logo dla adwokata."
        />
        <meta
          name="twitter:title"
          content="Identyfikacja wizualna kancelarii adwokackiej 2022"
        />
        <meta
          name="twitter:image"
          content="https://shovv.pl/img/herobrand.jpg"
        />
      </Helmet>
      <Header />
      <section
        className="section preview-section"
        style={
          supportWebp
            ? {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/herobrand.webp'
                })`,
                backgroundPosition: 'left',
              }
            : {
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/img/herobrand.jpg'
                })`,
              }
        }
      >
        <div className="scroll-bottom go-to">
          <a href="#identyfikacja">Scroll</a>
        </div>
        <Social />
      </section>

      {/* SEA IMAGES SECTION */}
      <section className="rectangles-section section light-grey pt-0">
        <div className="container ">
          <div className="row justify-content-center">
            <div
              id="identyfikacja"
              className="col-12 col-lg-8 bg-white white-part"
            >
              <h1 className="px-3 mb-4 mx-lg-4">
                <span>system identyfikacji wizualnej</span> KANCELARII
                ADWOKACKIEJ
              </h1>
              <p className="px-3 mb-3">
                Identyfikacja wizualna Kancelarii Prawnej nie zamyka się jedynie
                w logo i kolorystyce. Visual Identity to całościowa
                identyfikacja przedsiębiorstwa, która ma za zadanie
                systematyczne doskonalenie wizerunku Twojej kancelarii.
              </p>
              <p className="px-3 mb-3">
                System identyfikacji wizualnej stanowi zbiór znaków graficznych,
                zasad tworzenia dokumentów, zasad wykorzystania logo, typografii
                i nazwy.
              </p>
              <p className="px-3 mb-3">
                Należy wziąć pod uwagę wiele czynników podczas budowania
                korzystnego społecznego wizerunku kancelarii. Skuteczny system
                identyfikacji wizualnej pozwoli Ci wyróżnić się na tle
                konkurencji.
              </p>
            </div>
          </div>
        </div>
        <div className="container featured-content ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <picture>
                <source srcSet={imgWebp} type="image/webp" />
                <source srcSet={imgJPG} type="image/jpg" />
                <img
                  className="img-fluid w-100"
                  src={imgWebp}
                  type="image/webp"
                  alt="System identyfikacji wizualnej Kancelarii Adwokackiej"
                />
              </picture>
            </div>
          </div>
          <div className="row justify-content-center featured-content__wrapper">
            <div className="col-12 col-lg-4 bg-dark featured-content__text">
              <h3 className="px-3 mb-4">
                <span>PROJEKT LOGO DLA</span> KANCELARII ADWOKACKIEJ
              </h3>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Każda kancelaria prawna potrzebuje znaku, który będzie ją
                wyróżniać. Logo jest bazową częścią identyfikacji wizualnej.
              </p>
              <p className="px-3 mb-3">
                Na jego podstawie buduje się całość wizerunku kancelarii, który
                ma za zadanie przekazać odbiorcy cechy charakteryzujące Twój
                biznes.
              </p>
              <p className="px-3 mb-3">
                Pracę nad przygotowaniem projektu logo zaczynamy od omówienia
                wypełnionego przez Ciebie wcześniej briefu. Następnie
                analizujemy Twoją najbliższą konkurencję, aby jeszcze lepiej
                zrozumieć Twoje potrzeby.
              </p>
              <p className="px-3 mb-3">
                Posiadając te wszystkie informacje możemy przejść do opracowania
                graficznego naszych pomysłów.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*END  SEA IMAGES SECTION */}

      {/* FORM SECTION */}
      <section className="brief-form__section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-5">
              <h3 className="px-3 mb-4">
                <span>Darmowa wycena</span> wypełnij formularz
              </h3>

              <p className="px-3">
                Zapoznamy się z prowadzonym przez Ciebie biznesem i poznamy
                Twoją konkurencję, a następnie się skontaktujemy, aby wspólnie
                opracować strategię i wybrać optymalne rozwiązanie dopasowane do
                Twoich potrzeb.
              </p>
              <p className="px-3">
                Odpowiadamy w przeciągu 24h na pytania złożone od poniedziałku
                do piątku w godzinach 9:00 - 17:00. Na pozostałe zapytania
                odpowiadamy kolejnego dnia roboczego.
              </p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5">
              <Brief />
            </div>
          </div>
        </div>
      </section>
      {/*END FORM SECTION */}

      {/* NASZE REALIZACJE */}
      <section className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Nasze realizacje</h3>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="blog-post">
                <div className="blog-img">
                  <picture>
                    <source
                      srcSet="/img/ourwebs/logo-adwokat.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/img/ourwebs/logo-adwokat.jpg"
                      type="image/jpg"
                    />
                    <img
                      className="img-fluid w-100"
                      src="/img/ourwebs/logo-adwokat.webp"
                      type="image/webp"
                      alt="Identyfikacja wizualna Kancelarii Adwokackiej"
                    />
                  </picture>
                </div>
                {/* End blog-img */}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="blog-post">
                <div className="blog-img">
                  <picture>
                    <source
                      srcSet="/img/ourwebs/logo-seman.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/img/ourwebs/logo-seman.jpg"
                      type="image/jpg"
                    />
                    <img
                      className="img-fluid w-100"
                      src="/img/ourwebs/logo-seman.webp"
                      type="image/webp"
                      alt="Projekt Logo Kancelarii Radcy Prawnego"
                    />
                  </picture>
                </div>
                {/* End blog-img */}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="blog-post">
                <div className="blog-img">
                  <picture>
                    <source
                      srcSet="/img/ourwebs/logo-klim.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/img/ourwebs/logo-klim.jpg"
                      type="image/jpg"
                    />
                    <img
                      className="img-fluid w-100"
                      src="/img/ourwebs/logo-klim.webp"
                      type="image/webp"
                      alt="Projekt Logo Kancelarii Adwokackiej Justyna Klim"
                    />
                  </picture>
                </div>
                {/* End blog-img */}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="blog-post">
                <div className="blog-img">
                  <picture>
                    <source
                      srcSet="/img/ourwebs/logo-pkpo.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/img/ourwebs/logo-pkpo.jpg"
                      type="image/jpg"
                    />
                    <img
                      className="img-fluid w-100"
                      src="/img/ourwebs/logo-pkpo.webp"
                      type="image/webp"
                      alt="Identyfikacja wizualna Kancelarii Prawnej"
                    />
                  </picture>
                </div>
                {/* End blog-img */}
              </div>
            </div>
          </div>
          {/* End .row */}
        </div>
      </section>
      {/* END NASZE REALIZACJE */}

      <section className="section">
        <div className="container">
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>księga ZNAKU</span> KANCELARII
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Księga znaku (brand book) określenie i zbiór zasad poprawnego
                zastosowania znaków graficznych. Brand book jest przydatny we
                współpracy z osobami trzecimi np. przy wydrukach, a także przy
                tworzeniu pozostałych materiałów marketingowych.
              </p>
              <p className="px-3 mb-3">
                W księdze znaku znajdują się takie elementy jak logo,
                typografia, kolorystyka wraz z jej wariantami, proporcje oraz
                pole ochronne. Posiadanie i korzystanie z brand booka gwarantuje
                utrzymanie spójnego wizerunku kancelarii.
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>kolorystyka</span> kancelarii
              </h2>
              <p></p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Wybór odpowiedniego koloru ma wpływ na emocje, które pojawią się
                u odbiorcy. Częstym wyborem kancelarii prawnych jest granat oraz
                kolor niebieski ze swoimi odcieniami, który sprawdzi się w
                komunikacji z klientem biznesowym.
              </p>
              <p className="px-3 mb-3">
                Barwa niebieska jest pełna symboliki, często utożsamiana jest ze
                spokojem i opanowaniem, które mogą wpłynąć korzystnie na
                zaufanie.
              </p>
              <p className="px-3 mb-3">
                Kolor zielony, często kojarzony z adwokatem na sali sądowej
                zaczął obowiązywać dopiero od 1956 roku.
              </p>
              <p className="px-3 mb-3">
                Dobrze zaprojektowana kolorystyka powinna mieć swój odpowiednik
                w RGB, CMYK oraz Pantone
              </p>
            </div>
          </div>
          <div className="row justify-content-center content-row">
            <div className="col-12 col-lg-5">
              <h2 className="px-3 mb-4">
                <span>typografia</span> kancelarii
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="px-3 mb-3">
                Dopełnieniem identyfikacji wizualnej kancelarii prawnej jest
                stworzenie bądź też dobór odpowiedniej typografii. Typografia
                jest zbiorem krojów i rozmiarów czcionek.
              </p>
              <p className="px-3 mb-3">
                Czcionki możemy rozróżnić na: display, monospace, szeryfowe,
                bezszeryfowe. W logo znajduje się także sygnet, ważne aby
                typografia korespondowała z nim tworząc jako całość spójną
                kompozycje.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section content-row-center gray-bg">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div className="purchase-block-inner">
                {/* <Fade bottom> */}
                <h3 className="pt-0">
                  <span>papier firmowy</span>
                  oraz materiały reklamowe
                </h3>
                <p className="px-3">
                  Papier firmowy, wizytówka, teczka oraz koperta to podstawowy
                  zestaw materiałów reklamowych kancelarii. Są to także
                  narzędzia niezbędne do codziennego funkcjonowania biznesu.
                </p>
                <p className="px-3">
                  Akcydensy stanowiące element brandingu są projektowane od
                  podstaw równolegle z księgą znaku. Odpowiednio zaprojektowane
                  umożliwią budowanie rozpoznawalności kancelarii także offline.
                  Istotne jest aby materiały reklamowe tworzyły spójną
                  kompozycje.
                </p>
                <p className="px-3">
                  Zależnie od wymagań klienta projekty materiałów reklamowych
                  powinny zawierać wszystkie uszlachetnienia.
                </p>
                {/* </Fade> */}
              </div>
              {/* End .purchase-block-inner */}
            </div>
          </div>
        </div>
      </section>

      <section className="section our-technologies">
        <div className="container">
          <div className="row px-3">
            <div className="col-12">
              <h3>
                <span>technologie</span>
                <br /> w których pracujemy
              </h3>
            </div>
            <div className="col-6 col-md-2 ">
              <div className="box">
                <img
                  className="img-fluid w-100"
                  src="/img/technologies/photoshop.svg"
                  type="image/webp"
                  alt="Logotypy Kancelarii Prawnej Photoshop"
                />
              </div>
              <h5>Adobe Photoshop</h5>
            </div>
            <div className="col-6 offset-md-1 col-md-2 ">
              <div className="box">
                <img
                  className="img-fluid w-100"
                  src="/img/technologies/illustrator.svg"
                  type="image/webp"
                  alt="Identyfikacja Wizualna w Adobe Illustrator"
                />
              </div>
              <h5>Adobe Illustrator</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="rightSide-section">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-10 offset-lg-4 col-lg-8 rightSide-content">
              <h3 className="px-3">
                <span>dlaczego warto</span>
                <br /> współpracować z SHOVV
              </h3>
              <p className="px-3 mb-3">
                Od 2015 roku pomagamy Klientom w kreowaniu profesjonalnego
                wizerunku na rynku. Tworzymy nowe marki od podstaw, a także
                rozwijamy już istniejące.
              </p>
              <p className="px-3 mb-3">
                Wieloletnia obecność na rynku umożliwiła nam poznanie trendów i
                nowości w branży prawniczej, a także na zdobycie doświadczenia w
                tworzeniu wizerunków dobrze prosperujących kancelarii.
              </p>
              <p className="px-3 mb-3">
                Gwarantujemy wysoką jakość wykonywanych usług, w biznesie
                stawiamy na partnerstwo. Korzystamy z najnowszych programów
                Adobe.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="px-3 px-md-0">
                <span>ILE CZASU TRWA OPRACOWANIE </span>
                SYSTEMU IDENTYFIKACJI WIZUALNEJ?
              </h3>
              <p className="px-3 px-md-0">
                Warto pamiętać, że unikalna identyfikacja wizualna nie jest
                tworzona po to by zaspokoić potrzeby estetyczne właściciela
                kancelarii. Jej celem jest szerzenie tożsamości marki oraz
                budowanie jej świadomości.
              </p>
              <p className="px-3 px-md-0">
                Na dobry projekt identyfikacji wizualnej potrzebny jest czas.
                Czas potrzebny m. in. na analizę i zebranie informacji o
                kancelarii i jej otoczeniu. Stworzenie kilku koncepcji, z
                których w drodze eliminacji klient zobaczy 2-5. Następnie wybór
                koncepcji logo i dopracowanie identyfikacji wizualnej.
              </p>
              <p className="px-3 px-md-0">
                Należy też pamiętać o przygotowaniu podstawowych projektów
                graficznych na potrzeby poligrafii tj. papier firmowy,
                wizytówka, teczka, gadżety firmowy.
              </p>
              <p className="px-3 px-md-0">
                Zazwyczaj jest to proces który trwa od 2 do 4 tygodni.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <AfterRight /> */}

      <div className="section testimonial">
        <div className="container">
          <h3 className="text-center">
            <span>Klienci</span>o nas
          </h3>
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}
      {/* Contact */}
      <section id="contact" className="section after-left-section ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <h3>
                  <span>Napisz </span>do nas
                </h3>
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <AddressData />
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
};
export default CreateBrand;

import React, { useContext } from 'react';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AnimatedCursor from 'react-animated-cursor';
import LanguageContext from './providers/langContext';

import CookieConsent from 'react-cookie-consent';
import cookieImg from './assets/icons/cookie.svg';

const App = () => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <CookieConsent
        location="none"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        expires={365}
        disableStyles={true}
        buttonText={
          language === 'pl' ? 'Rozumiem i akceptuję' : 'I understand and accept'
        }
        buttonStyle={{
          background: '#7d7d7d',
          color: 'white',
        }}
        containerClasses="cookies-wrapper"
        contentClasses="cookies-text"
      >
        <div className="cookie-title">
          <img src={cookieImg} alt="cookie" />
          {language === 'pl' ? ' Polityka Cookie' : 'Cookie Privacy'}
        </div>
        {language === 'pl'
          ? 'Używamy plików Cookie, należących do naszej strony, a także do osób trzecich, w celu zrozumienia sposobów używania witryny shovv.pl oraz wsparcia kampanii marketingowych.'
          : 'We use cookies, belonging to our website and third parties, in order to understand the ways of using the shovv.pl website and to support marketing campaigns.'}
      </CookieConsent>

      <ScrollToTop />
      <Routes />
      <AnimatedCursor
        innerSize={12}
        outerSize={50}
        // color="255, 147, 1"
        color="94, 100, 114"
        outerAlpha={0.2}
        innerScale={0.41}
        outerScale={1}
      />
    </>
  );
};

export default App;
